import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { FileProtectOutlined } from '@ant-design/icons';
import { useDocumentTypesQuery, SortOrder } from 'GraphQL/queries/settings/documentTypes.graphql';
import { ResourceIndex } from 'Components';

const DocumentTypesView = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useDocumentTypesQuery}
        rootKey="documentTypes"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        createHref="/document-types/new"
        title="W&amp;R types"
        icon={<FileProtectOutlined />}
      >
        {(documentType) => (
          <tr key={documentType.id}>
            <td>
              <NavLink to={`/document-types/${documentType.id}/edit`}>{documentType.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default DocumentTypesView;
