import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserQuery } from 'GraphQL/queries/user.graphql';
import { Loading, UsersForm } from 'Components';

const UsersEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useUserQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <UsersForm
            user={data?.user}
            onDelete={() => navigate('/users')}
          />
        )}
    </section>
  );
};

export default UsersEdit;
