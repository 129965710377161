import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTextInterpretationStateQuery } from 'GraphQL/queries/settings/textInterpretationState.graphql';
import { useUpdateTextInterpretationStateMutation } from 'GraphQL/mutations/settings/updateTextInterpretationState.graphql';
import { useDeleteTextInterpretationStateMutation } from 'GraphQL/mutations/settings/deleteTextInterpretationState.graphql';
import { Loading, SettingsForm } from 'Components';

const TextInterpretationStatesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useTextInterpretationStateQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <SettingsForm
            entity="Interpretatie status"
            cacheKey="textInterpretationStates"
            settingsState={data?.textInterpretationState}
            onDelete={() => navigate('/text-interpretation-states')}
            updateHook={useUpdateTextInterpretationStateMutation}
            deleteHook={useDeleteTextInterpretationStateMutation}
          />
        )}
    </section>
  );
};

export default TextInterpretationStatesEdit;
