import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Link, Prompt } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Modal,
  notification,
  Row,
  Tabs,
  Typography,
  TreeSelect,
  Select,
} from 'antd';
import { TreeNode } from 'antd/lib/tree-select';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ButtonsBar } from 'Components';
import { PolicyQuery, Policy } from 'GraphQL/queries/policy.graphql';
import { useCreatePolicyMutation, Rank } from 'GraphQL/mutations/createPolicy.graphql';
import { useUpdatePolicyMutation } from 'GraphQL/mutations/updatePolicy.graphql';
import { useDeletePolicyMutation } from 'GraphQL/mutations/deletePolicy.graphql';
import { useMoveTextInterpretationsMutation } from 'GraphQL/mutations/moveTextInterpretations.graphql';
import { usePoliciesQuery } from 'GraphQL/queries/policies.graphql';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';
import useAuth from 'Hooks/useAuth';
import InterpretationsCollapse from './InterpretationsCollapse';
import style from './PoliciesForm.module.css';

interface PoliciesFormProps {
  policy?: PolicyQuery['policy'],
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

interface FormData {
  name: string
  graphLabel: string
  description: string
  strategy: string
  explanation: string
  rank: Rank
  inputFrom: number[]
}

const titleLabels = [
  'Domein',
  'Subdomein',
  'Doelstelling',
  'Product',
];

const renderPolicies = (policiesTree: any) => policiesTree.map((p: any) => (
  <TreeNode disabled={p.code.length < 4} key={p.key} value={p.key} title={`${p.code} ${p.name}`} name={p.name}>
    {p.children && renderPolicies(p.children)}
  </TreeNode>
));

const transforms = {
  belongsTo: [],
  hasMany: ['inputFrom'],
  date: [],
};

const flatten = (items: any[]): Policy[] => ([
  ...items,
  ...items.map((item) => flatten(item.children || [])),
].flat());

const PoliciesForm: FC<PoliciesFormProps> = ({ policy, onSubmit, onDelete }: PoliciesFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [selectedInterpretations, setSelectedInterpretations] = useState<number[]>([]);
  const [bulkMovePolicyId, setBulkMovePolicyId] = useState<number | undefined>(undefined);
  const [form] = Form.useForm();
  const level = useMemo(() => policy?.code.split('.').length || 1, [policy]);
  const { isAdmin } = useAuth();

  const [createPolicyMutation, { loading: loadingCreate }] = useCreatePolicyMutation({ ...invalidateCache('policies') });
  const [updatePolicyMutation, { loading: loadingUpdate }] = useUpdatePolicyMutation({ ...invalidateCache('policies') });
  const [deletePolicyMutation] = useDeletePolicyMutation({ ...invalidateCache('policies') });
  const [moveInterpretationMutation, { loading: loadingBulk }] = useMoveTextInterpretationsMutation({ ...invalidateCache('policy') });

  const { data: policiesData } = usePoliciesQuery({ fetchPolicy: 'network-only' });

  const flatPolicies = useMemo(() => {
    if (!policiesData) return [];
    return flatten(policiesData.policies);
  }, [policiesData]);

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createPolicyMutation({
        variables: {
          data: toGraphql(formData, transforms, 'CREATE'),
        },
      });
      notification.success({ message: 'Control framework is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Control framework toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updatePolicyMutation({
        variables: {
          data: toGraphql(formData, transforms, 'UPDATE'),
          id: policy!.id,
        },
      });
      notification.success({ message: 'Control framework is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Control framework opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deletePolicyMutation({
        variables: {
          data: {
            id: policy!.id,
            parentId: policy?.parentId,
            sortOrder: policy?.sortOrder,
          },
        },
      });
      notification.success({ message: 'Control framework is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Control framework verwijderen mislukt' });
    }
  };

  const handleCheckInterpretation = (event: CheckboxChangeEvent, interpretationId: number) => {
    if (event.target.checked) {
      setSelectedInterpretations((val) => [...val, interpretationId]);
    } else {
      setSelectedInterpretations((val) => val.filter((id) => id !== interpretationId));
    }
  };

  const handleBulkMove = async () => {
    if (!bulkMovePolicyId) return;

    try {
      await moveInterpretationMutation({
        variables: {
          interpretationIds: selectedInterpretations,
          policyId: bulkMovePolicyId,
        },
      });
      notification.success({ message: 'Interpretaties zijn verplaatst' });
      setBulkModal(false);
    } catch (e) {
      notification.error({ message: 'Interpretaties verplaatsen mislukt' });
    }
  };

  useEffect(() => form.resetFields(), [policy]);

  const value = policy ? fromGraphql(policy, transforms) : undefined;

  return (
    <>
      <div className={style.cover} />
      <Form
        className={style.form}
        form={form}
        layout="vertical"
        initialValues={value}
        onFinish={policy ? handleUpdate : handleCreate}
        validateMessages={VALIDATE_MESSAGES}
        onFieldsChange={() => setIsBlocking(true)}
      >
        <Prompt
          when={isBlocking && isAdmin}
          message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
        />

        <h2>
          {titleLabels[level - 1]}
          <span className={style.policyId}>{`#${policy?.id}`}</span>
        </h2>

        <Row gutter={10}>
          <Col sm={18}>
            <Form.Item
              label="Naam"
              name="name"
              rules={[{ required: true }]}
            >
              <Input
                addonBefore={(<div>{policy?.code}</div>)}
              />
            </Form.Item>
          </Col>
          <Col sm={6}>
            {level === 4 ? (
              <Form.Item
                label="Klasse"
                name="rank"
                rules={[{ required: true }]}
              >
                <Select placeholder="Klasse">
                  <Select.Option value="LOW">Laag</Select.Option>
                  <Select.Option value="MEDIUM">Middel</Select.Option>
                  <Select.Option value="HIGH">Hoog</Select.Option>
                  <Select.Option value="EXTREME">Extreem</Select.Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                label="Alternatief label voor Sunburst"
                name="graphLabel"
              >
                <Input />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item
          label={(level === 3) ? 'Doel' : 'Beschrijving'}
          name="description"
        >
          <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
        </Form.Item>

        {level > 2 && (
        <Row gutter={10}>
          {level === 4 && (
            <Col sm={12}>
              <Form.Item
                label="Beoordelingscriteria"
                name="strategy"
              >
                <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
              </Form.Item>
            </Col>
          )}
          <Col flex="auto">
            <Form.Item
              label="Toelichting"
              name="explanation"
            >
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
            </Form.Item>
          </Col>
        </Row>
        )}

        {level > 2 && (
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="Input van"
                name="inputFrom"
              >
                <TreeSelect
                  showSearch
                  multiple
                  style={{ width: '100%' }}
                  dropdownStyle={{ height: 'auto', overflow: 'auto' }}
                  placeholder="Selecteer beleidsregel"
                  allowClear
                  filterTreeNode={(searchValue, node) => new RegExp(searchValue, 'gi').test(node?.name)}
                >
                  {policiesData?.policies && renderPolicies(policiesData?.policies)}
                </TreeSelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Output naar">
                <ul className={style.outputList}>
                  {policy?.outputTo.map((i) => {
                    const item = flatPolicies.find((p) => p.id === i.id);
                    return (<li key={i.id}>{`${item?.code} - ${item?.name}`}</li>);
                  })}
                </ul>
              </Form.Item>
            </Col>
          </Row>
        )}

        {isAdmin && (
          <ButtonsBar>
            {policy && (
            <>
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                cancelText="Annuleren"
                okText="Ik weet het zeker"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u dit Control framework ${policy?.name} wilt verwijderen?`}
              </Modal>
            </>
            )}
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreate || loadingUpdate}
              icon={<CheckOutlined />}
            >
              Opslaan
            </Button>
          </ButtonsBar>
        )}
      </Form>
      {(level > 2) && (
        <section style={{ position: 'relative' }}>
          <>
            {selectedInterpretations.length > 0 && (
              <Button
                type="primary"
                onClick={() => setBulkModal(true)}
                className={style.bulkButton}
              >
                Verplaatsen
              </Button>
            )}
            <Modal
              onCancel={() => setBulkModal(false)}
              title="Interpretaties verplaatsen"
              visible={bulkModal}
              cancelText="Annuleren"
              okText="Verplaatsen"
              onOk={handleBulkMove}
              okButtonProps={{
                disabled: !bulkMovePolicyId,
                loading: loadingBulk,
              }}
            >
              <p>
                {`Selecteer de beleidsregel om de ${selectedInterpretations.length} geselecteerde interpraties naartoe te verplaatsen.`}
              </p>
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                dropdownStyle={{ height: 'auto', overflow: 'auto' }}
                placeholder="Selecteer beleidsregel"
                allowClear
                filterTreeNode={(searchValue, node) => new RegExp(searchValue, 'gi').test(node?.name)}
                value={bulkMovePolicyId}
                onChange={(val) => setBulkMovePolicyId(val)}
              >
                {policiesData?.policies && renderPolicies(policiesData?.policies)}
              </TreeSelect>
            </Modal>
          </>
          <Tabs>
            <Tabs.TabPane tab="Interpretaties" key="Interpretaties">
              {(policy?.documentInterpretations.length === 0)
                ? <p>Er zijn nog geen interpretaties gekoppeld.</p>
                : policy?.documentInterpretations.map((document) => (
                  <section
                    key={document.documentId}
                    className={style.document}
                  >
                    <h4>{document.shortName}</h4>
                    <InterpretationsCollapse
                      interpretations={document.interpretations}
                      selected={selectedInterpretations}
                      onChange={handleCheckInterpretation}
                    />
                  </section>
                ))}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Nieuwsberichten" key="Nieuwsberichten">
              {(policy?.newsMessages?.length === 0)
                ? <p>Er zijn nog geen nieuwsberichten gekoppeld.</p>
                : (
                  <List
                    dataSource={policy?.newsMessages || []}
                    renderItem={({ id, published, subject }) => (
                      <List.Item>
                        <Typography.Text ellipsis>
                          <span style={{ opacity: 0.6, marginRight: 5 }}>
                            {dayjs(published).format('DD-MM-YYYY')}
                          </span>
                          <Link to={`/news-messages/${id}/edit`}>
                            {subject}
                          </Link>
                        </Typography.Text>
                      </List.Item>
                    )}
                  />
                )}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Jurisprudentie" key="Jurisprudentie">
              {(policy?.jurisprudences?.length === 0)
                ? <p>Er is nog geen jurisprudentie gekoppeld.</p>
                : (
                  <List
                    dataSource={policy?.jurisprudences || []}
                    renderItem={({
                      id, dateOfVerdict, caseIdentifier, summary,
                    }) => (
                      <List.Item>
                        <List.Item.Meta
                          title={(
                            <Link to={`/jurisprudences/${id}/edit`}>
                              {caseIdentifier}
                            </Link>
                          )}
                          description={(
                            <Typography.Text
                              ellipsis
                              style={{ maxWidth: '100%', opacity: 0.6 }}
                            >
                              {summary}
                            </Typography.Text>
                          )}
                        />
                        <span style={{ opacity: 0.6, marginRight: 5 }}>
                          {dayjs(dateOfVerdict).format('DD-MM-YYYY')}
                        </span>
                      </List.Item>
                    )}
                  />
                )}
            </Tabs.TabPane>
          </Tabs>
        </section>
      )}
    </>
  );
};

export default PoliciesForm;
