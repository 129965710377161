import React from 'react';
import { Button, Form, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

type Props = {
  label: string,
  name: string,
  buttonText?: string,
  multiple?: boolean,
};

const AttachmentUpload = ({
  label, name, buttonText, multiple,
}: Props) => (
  <Form.Item
    label={label}
    name={name}
    valuePropName="fileList"
    getValueFromEvent={(event) => (Array.isArray(event) ? event : event?.fileList)}
  >
    <Upload
      beforeUpload={() => false}
      maxCount={multiple ? undefined : 1}
      className="display-block"
    >
      <Button block icon={<UploadOutlined />}>
        {buttonText || 'Selecteer bestand'}
      </Button>
    </Upload>
  </Form.Item>
);

export default AttachmentUpload;
