import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEmployeeQuery } from 'GraphQL/queries/employee.graphql';
import { Loading, EmployeesForm } from 'Components';

const EmployeesEdit = () => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useEmployeeQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <EmployeesForm
            employee={data?.employee}
            companyId={Number.parseInt(companyId, 10)}
            onDelete={() => navigate(`/companies/${companyId}/employees`)}
          />
        )}
    </section>
  );
};

export default EmployeesEdit;
