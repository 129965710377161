import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { HourglassOutlined } from '@ant-design/icons';
import { useEunomeaStatesQuery, SortOrder } from 'GraphQL/queries/settings/eunomeaStates.graphql';
import { ResourceIndex } from 'Components';

const EunomeaStatesIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useEunomeaStatesQuery}
        rootKey="eunomeaStates"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        createHref="/eunomea-states/new"
        title="W&amp;R-Eunomea status"
        icon={<HourglassOutlined />}
      >
        {(eunomeaState) => (
          <tr key={eunomeaState.id}>
            <td>
              <NavLink to={`/eunomea-states/${eunomeaState.id}/edit`}>{eunomeaState.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default EunomeaStatesIndex;
