import React from 'react';
import style from './Dot.module.css';

interface DotProps {
  title: string
}

const Dot = ({ title }: DotProps) => (
  <span
    className={style.dot}
    title={title}
  >
    {`${title[0]}`}
  </span>
);

export default Dot;
