import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { useCompaniesQuery, SortOrder, QueryMode } from 'GraphQL/queries/companies.graphql';
import { ResourceIndex } from 'Components';

const CompaniesIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useCompaniesQuery}
        rootKey="companies"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        search={{
          toWhere: (search) => {
            if (!search) return {};
            return { name: { contains: search, mode: QueryMode.Insensitive } };
          },
        }}
        createHref="/companies/new"
        title="Organisaties"
        icon={<BankOutlined />}
      >
        {(company) => (
          <tr key={company.id}>
            <td>
              <NavLink to={`/companies/${company.id}/edit`}>{company.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default CompaniesIndex;
