import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PoliciesForm } from 'Components';

const PoliciesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <PoliciesForm
        onSubmit={() => navigate('/policies')}
      />
    </section>
  );
};

export default PoliciesCreate;
