import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  TreeSelect,
} from 'antd';
import { TreeNode } from 'antd/lib/tree-select';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ButtonsBar, QuerySelect } from 'Components';
import { DATEPICKER_LOCALE, VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';
import useAuth from 'Hooks/useAuth';
import { useCreateJurisprudenceMutation } from 'GraphQL/mutations/createJurisprudence.graphql';
import { useUpdateJurisprudenceMutation } from 'GraphQL/mutations/updateJurisprudence.graphql';
import { useDeleteJurisprudenceMutation } from 'GraphQL/mutations/deleteJurisprudence.graphql';
import { useDocumentsQuery, SortOrder } from 'GraphQL/queries/documents.graphql';
import { usePoliciesQuery } from 'GraphQL/queries/policies.graphql';

type Jurisprudence = {
  id?: number
  caseIdentifier?: string | null
  context?: string | null
  summary?: string | null
  dateOfVerdict: Date
  documents?: {
    id: number
  }[] | null
  policies?: {
    id: number
  }[] | null
};

interface JurisprudencesFormProps {
  jurisprudence?: Jurisprudence | null,
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

interface FormData {
  caseIdentifier: string
  context: string
  summary: string
  dateOfVerdict: string
  documents: any
  policies: any
}

const transforms = {
  belongsTo: [],
  hasMany: ['documents', 'policies'],
  date: ['dateOfVerdict'],
  attachment: [],
};

const renderPolicies = (policiesTree: any) => policiesTree.map((p: any) => (
  <TreeNode disabled={p.code.length < 4} key={p.key} value={p.key} title={`${p.code} ${p.name}`} name={p.name}>
    {p.children && renderPolicies(p.children)}
  </TreeNode>
));

const JurisprudencesForm: FC<JurisprudencesFormProps> = ({ jurisprudence, onSubmit, onDelete }: JurisprudencesFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useAuth();

  const [createJurisprudenceMutation, { loading: loadingCreate }] = useCreateJurisprudenceMutation({ ...invalidateCache('jurisprudences') });
  const [updateJurisprudenceMutation, { loading: loadingUpdate }] = useUpdateJurisprudenceMutation();
  const [deleteJurisprudencesMutation] = useDeleteJurisprudenceMutation({ ...invalidateCache('jurisprudences') });

  const { data: policiesData } = usePoliciesQuery({ fetchPolicy: 'network-only' });

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createJurisprudenceMutation({
        variables: {
          data: toGraphql(formData, transforms, 'CREATE'),
        },
      });
      notification.success({ message: 'Jurisprudentie is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Jurisprudentie toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateJurisprudenceMutation({
        variables: {
          data: toGraphql(formData, transforms, 'UPDATE'),
          id: jurisprudence!.id!,
        },
      });
      notification.success({ message: 'Jurisprudentie is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Jurisprudentie opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteJurisprudencesMutation({
        variables: {
          data: {
            id: jurisprudence!.id,
          },
        },
      });
      notification.success({ message: 'Jurisprudentie is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Jurisprudentie verwijderen mislukt' });
    }
  };

  useEffect(() => form.resetFields(), [jurisprudence]);

  const initialValues = jurisprudence ? fromGraphql(jurisprudence, transforms) : undefined;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={initialValues}
      onFinish={jurisprudence ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && isAdmin}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
      />

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="ECLI"
            name="caseIdentifier"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Datum uitspraak"
            name="dateOfVerdict"
            rules={[{ required: true }]}
          >
            <DatePicker
              locale={DATEPICKER_LOCALE}
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Gerelateerde beleidsregels"
            name="policies"
          >
            <TreeSelect
              showSearch
              multiple
              style={{ width: '100%' }}
              dropdownStyle={{ height: 'auto', overflow: 'auto' }}
              placeholder="Selecteer beleidsregel"
              allowClear
              filterTreeNode={(searchValue, node) => new RegExp(searchValue, 'gi').test(node?.name)}
            >
              {policiesData?.policies && renderPolicies(policiesData?.policies)}
            </TreeSelect>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Gerelateerde Wet &amp; Regelgeving"
            name={['documents']}
          >
            <QuerySelect
              allowClear
              mode="multiple"
              query={useDocumentsQuery}
              rootKey="documents"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
              titleProp="shortName"
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Context"
        name="context"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      <Form.Item
        label="Uitspraak"
        name="summary"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      {isAdmin && (
        <ButtonsBar>
          {jurisprudence && (
            <>
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                cancelText="Annuleren"
                okText="Ik weet het zeker"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u de jurisprudentie ${jurisprudence?.caseIdentifier} wilt verwijderen?`}
              </Modal>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        </ButtonsBar>
      )}
    </Form>
  );
};

export default JurisprudencesForm;
