const numericSort = (a: string, b: string) => {
  const keyPartsA = a.split('.').map((i) => (Number(i).toString() === 'NaN' ? i : Number(i)));
  const keyPartsB = b.split('.').map((i) => (Number(i).toString() === 'NaN' ? i : Number(i)));
  for (let i = 0; i < Math.max(keyPartsA.length, keyPartsB.length); i++) {
    if (keyPartsA[i] > keyPartsB[i]) return 1;
    if (keyPartsA[i] < keyPartsB[i]) return -1;
  }
  return 0;
};

export default numericSort;
