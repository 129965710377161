import React, { FC, useEffect, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Switch,
  Upload,
} from 'antd';
import {
  CheckOutlined, DeleteOutlined, InboxOutlined, UploadOutlined,
} from '@ant-design/icons';
import {
  ButtonsBar, Loading, QuerySelect, AttachmentUpload,
} from 'Components';
import { useDocumentTypesQuery } from 'GraphQL/queries/settings/documentTypes.graphql';
import { useLifecycleStatesQuery } from 'GraphQL/queries/settings/lifecycleStates.graphql';
import { useEunomeaStatesQuery } from 'GraphQL/queries/settings/eunomeaStates.graphql';
import { useUsersQuery } from 'GraphQL/queries/users.graphql';
import { useCompaniesQuery, SortOrder, CompanyTypeNames } from 'GraphQL/queries/companies.graphql';
import { useApplicationCharacteristicsQuery } from 'GraphQL/queries/settings/applicationCharacteristics.graphql';
import { useCreateDocumentMutation } from 'GraphQL/mutations/createDocument.graphql';
import { useUpdateDocumentMutation } from 'GraphQL/mutations/updateDocument.graphql';
import { useDeleteDocumentMutation } from 'GraphQL/mutations/deleteDocument.graphql';
import { useImportDocumentTextsMutation } from 'GraphQL/mutations/importDocumentTexts.graphql';
import { DATEPICKER_LOCALE, VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import { RcFile } from 'antd/lib/upload';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';
import useAuth from 'Hooks/useAuth';

interface Document {
  id: number
  abbreviation?: string | null
  shortName: string
  version?: string | null
  name?: string | null
  sourceLocation?: string | null
  startingDate?: string | null
  lastReviewDate?: string | null
  copyrighted: boolean
  copyrightComment?: string | null
  description?: string | null
  scope?: string | null
  explanation?: string | null
  type?: {
    id: number
  } | null
  lifecycleState?: {
    id: number
  } | null
  eunomeaState?: {
    id: number
  } | null
  stakeholders?: {
    id: number
    name: string
    types: {
      id: number
    }[] | null
  }[] | null
  sourceAttachment?: {
    id: number
    name?: string | null
    url: string
  } | null
  defaultCharacteristics?: {
    id: number
    name?: string | null
  }[] | null
  optionalCharacteristics?: {
    id: number
    name?: string | null
  }[] | null
  expertUser?: {
    id: number
  } | null
  reviewUser?: {
    id: number
  } | null
}

interface DocumentsFormProps {
  document?: Document | null,
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

interface FormData {
  abbreviation: string
  shortName: string
  version: string
  name: string
  sourceLocation: string
  startingDate: string
  lastReviewDate: string
  copyrighted: boolean
  copyrightComment: string
  description: string
  scope: string
  explanation: string
  type: {
    id: number,
  }
  lifecycleState: {
    id: number,
  }
  eunomeaState: {
    id: number,
  }
  stakeholders: number[]
  sourceAttachment?: RcFile[]
  defaultCharacteristics: number[]
  optionalCharacteristics: number[]
  expertUser: {
    id: number,
  }
  reviewUser: {
    id: number,
  }
}

const transforms = {
  belongsTo: ['type', 'lifecycleState', 'eunomeaState', 'expertUser', 'reviewUser'],
  hasMany: ['stakeholders', 'defaultCharacteristics', 'optionalCharacteristics'],
  date: ['startingDate', 'lastReviewDate'],
  attachment: ['sourceAttachment'],
};

const DocumentsForm: FC<DocumentsFormProps> = ({ document, onSubmit, onDelete }: DocumentsFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useAuth();

  const [createDocumentMutation, { loading: loadingCreate }] = useCreateDocumentMutation({ ...invalidateCache('documents') });
  const [updateDocumentMutation, { loading: loadingUpdate }] = useUpdateDocumentMutation();
  const [deleteDocumentMutation] = useDeleteDocumentMutation({ ...invalidateCache('documents') });
  const [importMutation, { loading: loadingImport }] = useImportDocumentTextsMutation();

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      await createDocumentMutation({
        variables: {
          data: toGraphql(formData, transforms, 'CREATE'),
        },
      });
      notification.success({ message: 'Document is toegevoegd' });
    } catch (e) {
      notification.error({ message: 'Document toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateDocumentMutation({
        variables: {
          data: toGraphql(formData, transforms, 'UPDATE'),
          id: document!.id,
        },
      });
      notification.success({ message: 'Document is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Document opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteDocumentMutation({
        variables: {
          id: document!.id,
        },
      });
      notification.success({ message: 'Document is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Document verwijderen mislukt' });
    }
  };

  const handleUpload = (file: RcFile) => {
    importMutation({
      variables: {
        data: {
          documentId: document!.id,
          file,
        },
      },
    }).then(() => {
      setUploadModal(false);
      notification.success({ message: 'Teksten zijn geïmporteerd' });
    });

    return false;
  };

  useEffect(() => form.resetFields(), [document]);

  const value = document ? fromGraphql(document, transforms) : undefined;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={value}
      onFinish={document ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && isAdmin}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
      />

      <Row gutter={10}>
        <Col span={4}>
          <Form.Item
            label="Afkorting"
            name="abbreviation"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Korte naam"
            name="shortName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Versie"
            name="version"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Type"
            name={['type', 'id']}
            rules={[{ required: true }]}
          >
            <QuerySelect
              query={useDocumentTypesQuery}
              rootKey="documentTypes"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={24} lg={16}>
          <Form.Item
            label="Volledige naam"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Laatste controle"
            name="lastReviewDate"
          >
            <DatePicker
              locale={DATEPICKER_LOCALE}
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Stakeholders"
        name={['stakeholders']}
      >
        <QuerySelect
          allowClear
          mode="multiple"
          query={useCompaniesQuery}
          rootKey="companies"
          defaultScope={{
            where: { types: { some: { name: { equals: CompanyTypeNames.Publisher } } } },
            orderBy: { name: SortOrder.Asc },
          }}
        />
      </Form.Item>

      <Form.Item
        label="Bron locatie"
        name="sourceLocation"
      >
        <Input />
      </Form.Item>

      <Row gutter={10}>
        <Col span={8}>
          <AttachmentUpload
            label="Bron bestand"
            name="sourceAttachment"
          />
        </Col>
        <Col span={8}>
          <Form.Item
            label="Verplicht indien de organisatie"
            name={['defaultCharacteristics']}
          >
            <QuerySelect
              allowClear
              mode="multiple"
              query={useApplicationCharacteristicsQuery}
              rootKey="applicationCharacteristics"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Optioneel indien de organisatie"
            name={['optionalCharacteristics']}
          >
            <QuerySelect
              allowClear
              mode="multiple"
              query={useApplicationCharacteristicsQuery}
              rootKey="applicationCharacteristics"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col xs={12} lg={8}>
          <Form.Item
            label="Ingangsdatum"
            name="startingDate"
            rules={[{ required: true }]}
          >
            <DatePicker
              locale={DATEPICKER_LOCALE}
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col xs={12} lg={8}>
          <Form.Item
            label="Norm status"
            name={['lifecycleState', 'id']}
          >
            <QuerySelect
              allowClear
              query={useLifecycleStatesQuery}
              rootKey="lifecycleStates"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={8}>
          <Form.Item
            label="Eunomea status"
            name={['eunomeaState', 'id']}
          >
            <QuerySelect
              allowClear
              query={useEunomeaStatesQuery}
              rootKey="eunomeaStates"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10} style={{ justifyContent: 'space-between' }}>
        <Col xs={12} lg={3}>
          <Form.Item
            label="Auteursrechtelijk beschermd"
            name="copyrighted"
            valuePropName="checked"
          >
            <Switch
              checkedChildren="Ja"
              unCheckedChildren="Nee"
            />
          </Form.Item>
        </Col>
        <Col xs={12} lg={7}>
          <Form.Item
            label="Auteursrecht toelichting"
            name="copyrightComment"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={12} lg={7}>
          <Form.Item
            label="Normexpert"
            name={['expertUser', 'id']}
          >
            <QuerySelect
              allowClear
              query={useUsersQuery}
              rootKey="users"
              titleProp="fullName"
              defaultScope={{
                orderBy: { firstName: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={12} lg={7}>
          <Form.Item
            label="Reviewer"
            name={['reviewUser', 'id']}
          >
            <QuerySelect
              allowClear
              query={useUsersQuery}
              rootKey="users"
              titleProp="fullName"
              defaultScope={{
                orderBy: { firstName: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>

      </Row>

      <Form.Item
        label="Omschrijving"
        name="description"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      <Form.Item
        label="Toepassingsgebied"
        name="scope"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      <Form.Item
        label="Toelichting"
        name="explanation"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      <ButtonsBar>
        {document && (
          <>
            <Button onClick={() => setUploadModal(true)}>
              <Link to={`/documents/${document.id}/document-texts`}>Naar teksten</Link>
            </Button>
            {isAdmin && (
              <>
                <Button
                  icon={<UploadOutlined />}
                  onClick={() => setUploadModal(true)}
                >
                  Teksten importeren
                </Button>
                <Button
                  danger
                  type="primary"
                  onClick={() => setDeleteModal(true)}
                  icon={<DeleteOutlined />}
                >
                  Verwijderen
                </Button>
                <Modal
                  onCancel={() => setDeleteModal(false)}
                  title="Bevestig uw keuze"
                  visible={deleteModal}
                  cancelText="Annuleren"
                  okText="Ik weet het zeker"
                  onOk={handleDelete}
                  okButtonProps={{ danger: true }}
                >
                  {`Weet u zeker dat u document ${document?.name} wilt verwijderen?`}
                </Modal>
              </>
            )}
          </>
        )}
        {isAdmin && (
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        )}
      </ButtonsBar>

      <Modal
        onCancel={() => setUploadModal(false)}
        title="Teksten importeren"
        visible={uploadModal}
        footer={null}
      >
        <Alert
          showIcon
          type="warning"
          message="Let op: door nieuwe teksten te importeren zullen bestaande teksten en interpretaties van dit document worden verwijderd."
          style={{ marginBottom: 20 }}
        />
        {loadingImport ? (
          <div className="ant-upload ant-upload-drag">
            <span className="ant-upload ant-upload-btn">
              <div className="ant-upload-drag-icon">
                <Loading center />
              </div>
              <p className="ant-upload-hint">
                Bezig met importeren.
              </p>
            </span>
          </div>
        ) : (
          <Upload.Dragger
            accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            beforeUpload={handleUpload}
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-hint">
              Sleep een Excel-bestand hiernaartoe of selecteer een bestand.
            </p>
          </Upload.Dragger>
        )}
      </Modal>
    </Form>
  );
};

export default DocumentsForm;
