import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsForm } from 'Components';
import { useCreateTextInterpretationStateMutation } from 'GraphQL/mutations/settings/createTextInterpretationState.graphql';

const TextInterpretationStatesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <SettingsForm
        entity="Interpretatie status"
        cacheKey="textInterpretationStates"
        onSubmit={() => navigate('/text-interpretation-states')}
        createHook={useCreateTextInterpretationStateMutation}
      />
    </section>
  );
};

export default TextInterpretationStatesCreate;
