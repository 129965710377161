import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Typography, Layout } from 'antd';
import { SecurityScanOutlined } from '@ant-design/icons';
import { ResourceIndex } from 'Components';
import { useJurisprudencesQuery, SortOrder, QueryMode } from 'GraphQL/queries/jurisprudences.graphql';

const JurisprudencesIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useJurisprudencesQuery}
        defaultScope={{
          orderBy: [
            { dateOfVerdict: SortOrder.Desc },
            { caseIdentifier: SortOrder.Asc },
          ],
        }}
        search={{
          toWhere: (search) => {
            if (!search) return {};
            return {
              caseIdentifier: { contains: search, mode: QueryMode.Insensitive },
            };
          },
        }}
        searchParameter="ECLI"
        rootKey="jurisprudences"
        createHref="/jurisprudences/new"
        title="Jurisprudentie"
        icon={<SecurityScanOutlined />}
      >
        {({ id, caseIdentifier }) => (
          <tr key={id}>
            <td>
              <NavLink to={`/jurisprudences/${id}/edit`}>
                <Typography.Text ellipsis style={{ width: 350 }}>
                  {caseIdentifier}
                </Typography.Text>
              </NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default JurisprudencesIndex;
