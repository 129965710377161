import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLifecycleStateQuery } from 'GraphQL/queries/settings/lifecycleState.graphql';
import { useUpdateLifecycleStateMutation } from 'GraphQL/mutations/settings/updateLifecycleState.graphql';
import { useDeleteLifecycleStateMutation } from 'GraphQL/mutations/settings/deleteLifecycleState.graphql';
import { Loading, SettingsForm } from 'Components';

const LifecycleStatesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useLifecycleStateQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <SettingsForm
            entity="W&amp;-levenscyclus status"
            cacheKey="lifecycleStates"
            settingsState={data?.lifecycleState}
            onDelete={() => navigate('/lifecycle-states')}
            updateHook={useUpdateLifecycleStateMutation}
            deleteHook={useDeleteLifecycleStateMutation}
          />
        )}
    </section>
  );
};

export default LifecycleStatesEdit;
