import React, { useMemo } from 'react';
import { QueryHookOptions, QueryResult } from '@apollo/client';
import { Select } from 'antd';

interface IndexVariables {
  where?: any
  orderBy?: any
}

type IndexResultPropertyName<Q> = {
  [K in keyof Q]: NonNullable<NonNullable<Q>[K]> extends unknown[] ? K : never
}[keyof Q];

type IndexResultType<Q> = {
  [K in keyof Q]: Q[K] extends Array<infer T> ? T : never
}[keyof Q];

type IndexQuery<Q, V extends IndexVariables> = (options: QueryHookOptions<Q, V>) => QueryResult<Q, any>;

interface QuerySelectProps<Q, V extends IndexVariables, R extends IndexResultPropertyName<Q>> {
  allowClear?: boolean,
  query: IndexQuery<Q, V>,
  rootKey: R,
  mode?: 'multiple' | 'tags',
  defaultScope?: V,
  defaultValue?: string | string[] | number | number[],
  value?: string | string[] | number | number[],
  onChange?: (value: any, option: any) => void,
  titleProp?: string,
}

const QuerySelect = <Q, V extends IndexVariables, R extends IndexResultPropertyName<Q>>({
  allowClear,
  query,
  rootKey,
  mode,
  defaultScope,
  defaultValue,
  value,
  onChange,
  titleProp = 'name',
}: QuerySelectProps<Q, V, R>) => {
  const { data, loading } = query({
    variables: defaultScope,
  });

  const options = useMemo(() => (
    data?.[rootKey] as Array<IndexResultType<Q>> || []
  ), [data]);

  return (
    <Select
      allowClear={allowClear}
      mode={mode}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      loading={loading}
    >
      {options.map((option: any) => (
        <Select.Option
          key={option.id}
          value={option.id}
        >
          {option[titleProp]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default QuerySelect;
