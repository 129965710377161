import React from 'react';
import { Comment, List } from 'antd';
import dayjs from 'dayjs';
import { useDocumentTextCommentsQuery } from '../../GraphQL/queries/documentTextComments.graphql';
import style from './CommentList.module.css';

interface CommentFormProps {
  documentTextId: number
}

const CommentList = ({ documentTextId }: CommentFormProps) => {
  const { data } = useDocumentTextCommentsQuery({
    variables: {
      id: documentTextId,
    },
    fetchPolicy: 'network-only',
  });

  return (
    <List
      className={style.commentList}
      dataSource={data?.documentText?.comments}
      renderItem={({
        createdAt, text, user: { firstName, lastNamePrefix, lastName },
      }) => (
        <Comment
          author={[firstName, lastNamePrefix, lastName].filter(Boolean).join(' ')}
          content={text}
          datetime={dayjs(createdAt).format('DD-MM-YYYY - HH:mm')}
        />
      )}
    />
  );
};

export default CommentList;
