import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import useAuth from 'Hooks/useAuth';

export interface PrivateRouteProps {
  element?: React.ReactElement,
  path: string,
  children?: React.ReactNode,
}

const PrivateRoute = ({
  element, path, children,
}: PrivateRouteProps) => {
  const { isAuthenticated, loading } = useAuth();

  if (!isAuthenticated && !loading) {
    return <Navigate to="/login" />;
  }

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
    >
      <Route
        path={path}
        element={element}
      >
        {children}
      </Route>
    </Spin>
  );
};

export default PrivateRoute;
