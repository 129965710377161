import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { useTextInterpretationStatesQuery, SortOrder } from 'GraphQL/queries/settings/textInterpretationStates.graphql';
import { ResourceIndex } from 'Components';

const TextInterpretationStatesIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useTextInterpretationStatesQuery}
        rootKey="textInterpretationStates"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        createHref="/text-interpretation-states/new"
        title="Interpretatie status"
        icon={<BulbOutlined />}
      >
        {(textInterpretationState) => (
          <tr key={textInterpretationState.id}>
            <td>
              <NavLink to={`/text-interpretation-states/${textInterpretationState.id}/edit`}>{textInterpretationState.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default TextInterpretationStatesIndex;
