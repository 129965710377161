import React, { useMemo } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Checkbox,
} from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import useAuth from 'Hooks/useAuth';
import { VALIDATE_MESSAGES } from 'Common/translations';
import logo from 'Assets/logo.png';
import styles from './Login.module.css';

interface FormData {
  code: string,
  trustDevice: boolean,
}

const TotpVerifyView = () => {
  const { totpVerify, totpVerifyLoading, totpVerifyError } = useAuth();

  const handleSubmit = async (formData: FormData) => {
    try {
      await totpVerify({ variables: formData });
    } catch (error) {
      // error is rendered with loginError
    }
  };

  const hasError = useMemo(() => totpVerifyError?.graphQLErrors, [totpVerifyError]);

  return (
    <div className={styles.login}>
      <Form
        onFinish={handleSubmit}
        validateMessages={VALIDATE_MESSAGES}
        layout="vertical"
        initialValues={{
          code: '',
          trustDevice: false,
        }}
      >
        <img
          className={styles.logo}
          src={logo}
          alt="Eunomea"
        />

        {hasError && (
        <Alert
          type="error"
          message="U hebt een ongeldige verificatie code ingevoerd"
          style={{ marginBottom: 18, fontSize: 13 }}
        />
        )}

        <h2>Voer uw code in</h2>

        <p>Voer de code uit uw verificatie-app in.</p>

        <Form.Item
          name="code"
          rules={[{ required: true }]}
        >
          <Input
            autoComplete="one-time-code"
            inputMode="numeric"
            pattern="[0-9]*"
            autoFocus
          />
        </Form.Item>

        <Form.Item name="trustDevice" valuePropName="checked">
          <Checkbox>Vertrouw dit apparaat voor 30 dagen</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            block
            htmlType="submit"
            icon={<LoginOutlined />}
            loading={totpVerifyLoading}
            type="primary"
          >
            Verifiëren
          </Button>
        </Form.Item>
        <p>Ongeautoriseerde toegang is verboden</p>
      </Form>
    </div>
  );
};

export default TotpVerifyView;
