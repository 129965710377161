import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNewsMessageQuery } from 'GraphQL/queries/newsMessage.graphql';
import { NewsMessagesForm, Loading } from 'Components';

const NewsMessagesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useNewsMessageQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <NewsMessagesForm
            newsMessage={data?.newsMessage}
            onDelete={() => navigate('/news-messages')}
          />
        )}
    </section>
  );
};

export default NewsMessagesEdit;
