import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
} from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ButtonsBar } from 'Components';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import useAuth from 'Hooks/useAuth';

interface SettingsFormProps {
  cacheKey: string,
  createHook?: any
  deleteHook?: any,
  entity: string,
  nameLabel?: string,
  descriptionLabel?: string,
  onDelete?: (user: any) => void,
  onSubmit?: (user: any) => void,
  settingsState?: any,
  updateHook?: any,
}

interface FormData {
  description: string
  name: string
}

const CreateSettingsState: FC<SettingsFormProps> = ({
  cacheKey,
  createHook = () => [() => {}, { loadingCreate: false }],
  deleteHook = () => [() => {}, { loadingDelete: false }],
  entity,
  nameLabel,
  descriptionLabel,
  onDelete,
  onSubmit,
  settingsState,
  updateHook = () => [() => {}, { loadingUpdate: false }],
}: SettingsFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useAuth();
  const [createMutation, { loading: loadingCreate }] = createHook({ ...invalidateCache(cacheKey) });
  const [deleteMutation] = deleteHook({ ...invalidateCache(cacheKey) });
  const [updateMutation, { loading: loadingUpdate }] = updateHook();

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createMutation({ variables: { data: formData } });
      notification.success({ message: `${entity} is toegevoegd` });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: `${entity} toevoegen mislukt` });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateMutation({
        variables: {
          data: formData,
          id: settingsState!.id,
        },
      });
      notification.success({ message: `${entity} is bewerkt` });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: `${entity} opslaan mislukt` });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteMutation({ variables: { id: settingsState!.id } });
      notification.success({ message: `${entity} is verwijderd` });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: `${entity} verwijderen mislukt` });
    }
  };

  useEffect(() => form.resetFields(), [settingsState]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={settingsState || undefined}
      onFinish={settingsState ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && isAdmin}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wilt verlaten?"
      />

      <Form.Item
        style={{ maxWidth: 350 }}
        label={nameLabel || 'Naam'}
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 710 }}
        label={descriptionLabel || 'Beschrijving'}
        name="description"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      {isAdmin && (
        <ButtonsBar>
          {settingsState && (
            <>
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                cancelText="Annuleren"
                okText="Ik weet het zeker"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u document-type ${settingsState?.name} wilt verwijderen?`}
              </Modal>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        </ButtonsBar>
      )}
    </Form>
  );
};

export default CreateSettingsState;
