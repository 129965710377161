import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsForm } from 'Components';
import { useCreateEunomeaStateMutation } from 'GraphQL/mutations/settings/createEunomeaState.graphql';

const EunomeaStatesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <SettingsForm
        entity="W&R-Eunomea status"
        cacheKey="eunomeaStates"
        onSubmit={() => navigate('/eunomea-states')}
        createHook={useCreateEunomeaStateMutation}
      />
    </section>
  );
};

export default EunomeaStatesCreate;
