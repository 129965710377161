import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CompaniesForm } from 'Components';

const CompaniesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <CompaniesForm
        onSubmit={() => navigate('/companies')}
      />
    </section>
  );
};

export default CompaniesCreate;
