import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { writeToken } from 'Common/token';
import { useCurrentUserQuery } from 'GraphQL/queries/currentUser.graphql';
import { useLoginMutation } from 'GraphQL/mutations/login.graphql';
import { useLogoutMutation } from 'GraphQL/mutations/logout.graphql';
import { useTotpVerifyMutation } from 'GraphQL/mutations/totpVerify.graphql';
import { useTotpEnableMutation } from 'GraphQL/mutations/totpEnable.graphql';

const useAuth = () => {
  const navigate = useNavigate();

  const {
    client, loading, data, refetch,
  } = useCurrentUserQuery({
    errorPolicy: 'ignore',
  });

  const currentUser = useMemo(() => data?.currentUser, [data]);
  const isAuthenticated = useMemo(() => currentUser?.currentSession?.secondFactor, [currentUser]);
  const isAdmin = useMemo(() => currentUser?.role === 'ADMIN', [currentUser]);

  const [login, { loading: loginLoading, error: loginError }] = useLoginMutation({
    onCompleted({ session }) {
      writeToken(session.token);
      client.resetStore();
      if (session.secondFactor) {
        navigate('/');
      } else if (session.user.secondFactorEnabled) {
        navigate('/totp-verify');
      } else {
        navigate('/totp-enable');
      }
    },
  });

  const [totpVerify, { loading: totpVerifyLoading, error: totpVerifyError }] = useTotpVerifyMutation({
    onCompleted({ session }) {
      writeToken(session.deviceToken, 'device');
      client.resetStore();
      navigate('/');
    },
  });

  const [totpEnable, { loading: totpEnableLoading, error: totpEnableError }] = useTotpEnableMutation({
    onCompleted() {
      client.resetStore();
      navigate('/');
    },
  });

  const [logout] = useLogoutMutation({
    onCompleted() {
      writeToken(null);
      navigate('/');
      client.resetStore();
    },
  });

  const setToken = (jwt: string) => {
    writeToken(jwt);
    client.resetStore();
  };

  const hasRole = (...roles: string[]) => roles.includes(currentUser?.role || '');

  return {
    currentUser,
    loading,
    isAuthenticated,
    isAdmin,
    hasRole,
    login,
    loginLoading,
    loginError,
    totpVerify,
    totpVerifyLoading,
    totpVerifyError,
    totpEnable,
    totpEnableLoading,
    totpEnableError,
    logout,
    setToken,
    refetch,
  };
};

export default useAuth;
