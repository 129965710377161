import React, { FC, useEffect, useState } from 'react';
import { Link, Prompt } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
} from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ButtonsBar, QuerySelect } from 'Components';
import { useCreateCompanyMutation } from 'GraphQL/mutations/createCompany.graphql';
import { useUpdateCompanyMutation } from 'GraphQL/mutations/updateCompany.graphql';
import { useDeleteCompanyMutation } from 'GraphQL/mutations/deleteCompany.graphql';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import useAuth from 'Hooks/useAuth';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';
import { useCompanyTypesQuery, SortOrder } from 'GraphQL/queries/settings/companyTypes.graphql';
import { useDocumentsQuery } from 'GraphQL/queries/documents.graphql';

interface Company {
  id: number,
  address?: string | null,
  kvkNumber?: string | null,
  name: string,
  postalCode?: string | null,
  postalCodePlace?: string | null,
  types: {
    id: number,
    name: string,
  }[] | null,
  documentsSubjectTo?: {
    id: number,
    shortName: string,
  }[] | null
}

interface CompaniesFormProps {
  company?: Company | null,
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

interface FormData {
  address: string,
  kvkNumber: string,
  name: string,
  postalCode: string,
  postalCodePlace: string,
  types: number[],
  documentsSubjectTo: number[],
}

const transforms = {
  hasMany: ['types', 'documentsSubjectTo'],
};

const CompaniesForm: FC<CompaniesFormProps> = ({ company, onSubmit, onDelete }: CompaniesFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useAuth();

  const [createCompanyMutation, { loading: loadingCreate }] = useCreateCompanyMutation({ ...invalidateCache('companies') });
  const [updateCompanyMutation, { loading: loadingUpdate }] = useUpdateCompanyMutation();
  const [deleteCompanyMutation] = useDeleteCompanyMutation({ ...invalidateCache('companies') });

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createCompanyMutation({
        variables: {
          data: toGraphql(formData, transforms, 'CREATE'),
        },
      });
      notification.success({ message: 'Organisatie is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Organisatie toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateCompanyMutation({
        variables: {
          data: toGraphql(formData, transforms, 'UPDATE'),
          id: company!.id,
        },
      });
      notification.success({ message: 'Organisatie is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Organisatie opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteCompanyMutation({
        variables: { id: company!.id },
      });
      notification.success({ message: 'Organisatie is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Organisatie verwijderen mislukt' });
    }
  };

  useEffect(() => form.resetFields(), [company]);

  const value = company ? fromGraphql(company, transforms) : undefined;

  const isClient = company?.types?.some(({ name }) => name === 'CUSTOMER');

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={value}
      onFinish={company ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && isAdmin}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
      />

      <h3>Organisatie</h3>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Types"
        name="types"
        rules={[{ required: true }]}
      >
        <QuerySelect
          allowClear
          mode="multiple"
          query={useCompanyTypesQuery}
          rootKey="companyTypes"
          titleProp="description"
          defaultScope={{
            orderBy: { name: SortOrder.Asc },
          }}
        />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        tooltip="Naam van het bedrijf zoals ingeschreven bij de KvK"
        label="Bedrijfsnaam"
        name="name"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="KvK-nummer"
        name="kvkNumber"
      >
        <Input maxLength={8} />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="BTW-nummer"
        name="btwNumber"
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Telefoonnummer algemeen"
        name="telephoneNumber"
      >
        <Input />
      </Form.Item>

      <h3>Adres</h3>

      <Form.Item
        style={{ maxWidth: 350 }}
        tooltip={{ title: 'Straatnaam en nummer' }}
        label="Adres"
        name="address"
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Postcode"
        name="postalCode"
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Plaatsnaam"
        name="postalCodePlace"
        tooltip="De vestigingsplaats van het hoofdkantoor."
      >
        <Input />
      </Form.Item>

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Land"
        name="country"
      >
        <Input />
      </Form.Item>

      <h3>Eunomea</h3>

      {isClient && (
      <Form.Item
        style={{ maxWidth: 350 }}
        label="Afgenomen wet- en regelgeving"
        name="documentsSubjectTo"
      >
        <QuerySelect
          allowClear
          mode="multiple"
          query={useDocumentsQuery}
          rootKey="documents"
          titleProp="shortName"
          defaultScope={{
            orderBy: { name: SortOrder.Asc },
          }}
        />
      </Form.Item>
      )}

      <Form.Item
        style={{ maxWidth: 350 }}
        label="Opmerkingen"
        name="remarks"
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      {isAdmin && (
        <ButtonsBar>
          {company && (
            <>
              {company.types?.find((type) => type.name === 'CUSTOMER') && (
                <Button>
                  <Link to={`/companies/${company.id}/employees`}>Naar medewerkers</Link>
                </Button>
              )}
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                cancelText="Annuleren"
                okText="Ik weet het zeker"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u de organisatie ${company?.name} wilt verwijderen?`}
              </Modal>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        </ButtonsBar>
      )}

    </Form>
  );
};

export default CompaniesForm;
