import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Modal,
  notification,
} from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { ButtonsBar, QuerySelect } from 'Components';
import { useCreateEmployeeMutation } from 'GraphQL/mutations/createEmployee.graphql';
import { useUpdateEmployeeMutation } from 'GraphQL/mutations/updateEmployee.graphql';
import { useDeleteEmployeeMutation } from 'GraphQL/mutations/deleteEmployee.graphql';
import { useUsersQuery, SortOrder } from 'GraphQL/queries/users.graphql';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import useAuth from 'Hooks/useAuth';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';

interface Props {
  employee?: {
    id: number,
    firstName?: string | null,
    lastNamePrefix?: string | null,
    lastName?: string | null,
    user?: {
      id: number
    } | null
  } | null,
  companyId: number,
  onSubmit?: (employee: any) => void,
  onDelete?: (employee: any) => void,
}

interface FormData {
  firstName: string
  lastNamePrefix: string
  lastName: string
  user: {
    id: number,
  }
}

const transforms = {
  belongsTo: ['user'],
};

const EmployeesForm: FC<Props> = ({
  employee, companyId, onSubmit, onDelete,
}: Props) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useAuth();

  const [createEmployeeMutation, { loading: loadingCreate }] = useCreateEmployeeMutation({ ...invalidateCache('employees') });
  const [updateEmployeeMutation, { loading: loadingUpdate }] = useUpdateEmployeeMutation();
  const [deleteEmployeeMutation] = useDeleteEmployeeMutation({ ...invalidateCache('employees') });

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createEmployeeMutation({
        variables: {
          data: {
            ...toGraphql(formData, transforms, 'CREATE'),
            company: { connect: { id: companyId } },
          },
        },
      });
      notification.success({ message: 'Medewerker is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Medewerker toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateEmployeeMutation({
        variables: {
          data: toGraphql(formData, transforms, 'UPDATE'),
          id: employee!.id,
        },
      });
      notification.success({ message: 'Medewerker is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Medewerker opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteEmployeeMutation({ variables: { id: employee!.id } });
      notification.success({ message: 'Medewerker is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Medewerker verwijderen mislukt' });
    }
  };

  useEffect(() => form.resetFields(), [employee]);

  const value = employee ? fromGraphql(employee, transforms) : undefined;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={value}
      onFinish={employee ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && isAdmin}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
      />
      <Row gutter={10}>
        <Col span={4}>
          <Form.Item
            label="Voornaam "
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item
            label="Tussenvoegsel"
            name="lastNamePrefix"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Achternaam" name="lastName" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={6}>
          <Form.Item
            label="Functie"
            name="position"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Gebruiker"
            name={['user', 'id']}
          >
            <QuerySelect
              allowClear
              query={useUsersQuery}
              rootKey="users"
              titleProp="email"
              defaultScope={{
                orderBy: { email: SortOrder.Asc },
              }}
            />
          </Form.Item>

        </Col>
      </Row>

      {isAdmin && (
        <ButtonsBar>
          {employee && (
            <>
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                okText="Ik weet het zeker"
                cancelText="Annuleren"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u ${employee.firstName} ${employee.lastNamePrefix || ''} ${employee.lastName} wilt verwijderen?`}
              </Modal>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        </ButtonsBar>
      )}
    </Form>
  );
};

export default EmployeesForm;
