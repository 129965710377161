import React, { FC, useMemo } from 'react';
import {
  Checkbox,
  Col,
  Collapse,
  Row,
} from 'antd';
import { isPresent } from 'ts-is-present';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { usePoliciesQuery, Policy } from 'GraphQL/queries/policies.graphql';
import numericSort from 'Common/numericSort';
import style from './InterpretationsCollapse.module.css';

interface DescriptionProps {
  label: string,
  children: React.ReactNode,
}

const Description: FC<DescriptionProps> = ({ label, children }: DescriptionProps) => (
  <section className={style.description}>
    <span>{label}</span>
    <div>
      {children}
    </div>
  </section>
);

type RelatedInterpretation = {
  policy?: {
    id: number
  } | null
};

type Interpretation = {
  id: number
  inputFromInterpretations: RelatedInterpretation[]
  outputToInterpretations: RelatedInterpretation[]
  sortId: string
  subject: string
  specification: string
  productResult?: string | null
  examples?: string | null
  requirements?: string | null
  text: {
    code: string
  }
};

interface InterpretationCollapseProps {
  interpretations?: Interpretation[] | null,
  selected: number[],
  onChange: (event: CheckboxChangeEvent, interpretationId: number) => void,
}

const flatten = (items: any[]): Policy[] => ([
  ...items,
  ...items.map((item) => flatten(item.children || [])),
].flat());

const InterpretationsCollapse: FC<InterpretationCollapseProps> = ({ interpretations, selected, onChange }: InterpretationCollapseProps) => {
  const { data: policiesData } = usePoliciesQuery();

  const flatPolicies = useMemo(() => {
    if (!policiesData) return [];
    return flatten(policiesData.policies);
  }, [policiesData]);

  const policyLabels = (items?: RelatedInterpretation[] | null) => {
    if (!items) return [];

    const uniquePolicyIds = [...new Set(items.map(({ policy }) => policy).filter(isPresent).map(({ id }) => id))];
    return uniquePolicyIds.map((id) => flatPolicies.find((p) => p.id === id)).filter(isPresent);
  };

  const sortedInterpretations = useMemo(() => {
    if (!interpretations) return [];

    return [...interpretations].sort((a, b) => (
      numericSort(`${a.text.code}.${a.sortId}`, `${b.text.code}.${b.sortId}`)
    ));
  }, [interpretations]);

  return (
    <Collapse bordered={false} className={style.collapse}>
      {sortedInterpretations.map((interpretation) => (
        <Collapse.Panel
          header={(
            <>
              <Row>
                <Col span={2}>
                  {`${interpretation.text.code}.${interpretation.sortId}`}
                </Col>
                <Col>
                  {interpretation.subject}
                </Col>
              </Row>
              <Checkbox
                checked={selected.includes(interpretation.id)}
                onChange={(event) => onChange(event, interpretation.id)}
                onClick={(event) => event.stopPropagation()}
              />
            </>
        )}
          key={interpretation.id}
        >
          <Row gutter={10}>
            <Col xs={24}>
              <Description label="Specificatie">
                <p>{interpretation.specification}</p>
              </Description>
            </Col>
            <Col xs={24}>
              <Description label="Specifieke eisen">
                <p>{interpretation.requirements}</p>
              </Description>
            </Col>
            <Col xs={12}>
              <Description label="Resultaat">
                <p>{interpretation.productResult}</p>
              </Description>
            </Col>
            <Col xs={12}>
              <Description label="Voorbeeld van bewijs">
                <p>{interpretation.examples}</p>
              </Description>
            </Col>
            <Col xs={12}>
              <Description label="Input van">
                <ul className={style.inputOutputList}>
                  {policyLabels(interpretation.inputFromInterpretations).map((p) => (
                    <li key={p.id}>{`${p.code} ${p.name}`}</li>
                  ))}
                </ul>
              </Description>
            </Col>
            <Col xs={12}>
              <Description label="Output naar">
                <ul className={style.inputOutputList}>
                  {policyLabels(interpretation.outputToInterpretations).map((p) => (
                    <li key={p.id}>{`${p.code} ${p.name}`}</li>
                  ))}
                </ul>
              </Description>
            </Col>
          </Row>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};

export default InterpretationsCollapse;
