import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { useTermsQuery, SortOrder, QueryMode } from 'GraphQL/queries/settings/terms.graphql';
import { ResourceIndex } from 'Components';

const TermsIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useTermsQuery}
        rootKey="terms"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        search={{
          toWhere: (search) => {
            if (!search) return {};
            return { name: { contains: search, mode: QueryMode.Insensitive } };
          },
        }}
        createHref="/terms/new"
        createRoles={['ADMIN', 'TERMS_EDITOR']}
        title="Begrippen"
        icon={<ProfileOutlined />}
      >
        {(term) => (
          <tr key={term.id}>
            <td>
              <NavLink to={`/terms/${term.id}/edit`}>{term.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default TermsIndex;
