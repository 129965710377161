import React from 'react';
import {
  HashRouter as Router, Routes, Route, Navigate,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ApolloProvider } from '@apollo/client';
import client from 'Common/apollo';
import { Layout, PrivateRoute } from 'Components';
import {
  ApplicationCharacteristicsCreate,
  ApplicationCharacteristicsEdit,
  ApplicationCharacteristicsIndex,
  CommentsIndex,
  CompaniesCreate,
  CompaniesEdit,
  CompaniesIndex,
  DocumentsCreate,
  DocumentsEdit,
  DocumentsIndex,
  DocumentTextsEdit,
  DocumentTextsIndex,
  DocumentTypesCreate,
  DocumentTypesEdit,
  DocumentTypesIndex,
  EmployeesCreate,
  EmployeesEdit,
  EmployeesIndex,
  EunomeaStatesCreate,
  EunomeaStatesEdit,
  EunomeaStatesIndex,
  JurisprudencesCreate,
  JurisprudencesEdit,
  JurisprudencesIndex,
  LifecycleStatesCreate,
  LifecycleStatesEdit,
  LifecycleStatesIndex,
  PoliciesCreate,
  PoliciesEdit,
  PoliciesIndex,
  Login,
  TotpVerify,
  TotpEnable,
  TermsCreate,
  TermsEdit,
  TermsIndex,
  NewsCategoriesCreate,
  NewsCategoriesEdit,
  NewsCategoriesIndex,
  NewsMessagesCreate,
  NewsMessagesEdit,
  NewsMessagesIndex,
  TextInterpretationStatesCreate,
  TextInterpretationStatesEdit,
  TextInterpretationStatesIndex,
  UsersCreate,
  UsersEdit,
  UsersIndex,
} from 'Views';
import 'Assets/styles.css';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function App() {
  const environment = window.location.host === 'staging.eunomea.nl' ? 'staging' : 'production';

  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ApolloProvider client={client}>
        <Router>
          <Layout className={environment}>
            <Routes>
              <Route path="/" element={<Navigate to="/documents" />} />
              <Route path="/login" element={<Login />} />
              <Route path="/totp-enable" element={<TotpEnable />} />
              <Route path="/totp-verify" element={<TotpVerify />} />

              <PrivateRoute path="/policies" element={<PoliciesIndex />}>
                <Route path="/new" element={<PoliciesCreate />} />
                <Route path="/:id/edit" element={<PoliciesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/users" element={<UsersIndex />}>
                <Route path="/new" element={<UsersCreate />} />
                <Route path="/:id/edit" element={<UsersEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/documents" element={<DocumentsIndex />}>
                <Route path="/new" element={<DocumentsCreate />} />
                <Route path="/:id/edit" element={<DocumentsEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/documents/:documentId/document-texts" element={<DocumentTextsIndex />}>
                <Route path="/:id/edit" element={<DocumentTextsEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/companies" element={<CompaniesIndex />}>
                <Route path="/new" element={<CompaniesCreate />} />
                <Route path="/:id/edit" element={<CompaniesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/companies/:companyId/employees" element={<EmployeesIndex />}>
                <Route path="/new" element={<EmployeesCreate />} />
                <Route path="/:id/edit" element={<EmployeesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/document-types" element={<DocumentTypesIndex />}>
                <Route path="/new" element={<DocumentTypesCreate />} />
                <Route path="/:id/edit" element={<DocumentTypesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/application-characteristics" element={<ApplicationCharacteristicsIndex />}>
                <Route path="/new" element={<ApplicationCharacteristicsCreate />} />
                <Route path="/:id/edit" element={<ApplicationCharacteristicsEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/eunomea-states" element={<EunomeaStatesIndex />}>
                <Route path="/new" element={<EunomeaStatesCreate />} />
                <Route path="/:id/edit" element={<EunomeaStatesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/lifecycle-states" element={<LifecycleStatesIndex />}>
                <Route path="/new" element={<LifecycleStatesCreate />} />
                <Route path="/:id/edit" element={<LifecycleStatesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/text-interpretation-states" element={<TextInterpretationStatesIndex />}>
                <Route path="/new" element={<TextInterpretationStatesCreate />} />
                <Route path="/:id/edit" element={<TextInterpretationStatesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/terms" element={<TermsIndex />}>
                <Route path="/new" element={<TermsCreate />} />
                <Route path="/:id/edit" element={<TermsEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/news-categories" element={<NewsCategoriesIndex />}>
                <Route path="/new" element={<NewsCategoriesCreate />} />
                <Route path="/:id/edit" element={<NewsCategoriesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/news-messages" element={<NewsMessagesIndex />}>
                <Route path="/new" element={<NewsMessagesCreate />} />
                <Route path="/:id/edit" element={<NewsMessagesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/jurisprudences" element={<JurisprudencesIndex />}>
                <Route path="/new" element={<JurisprudencesCreate />} />
                <Route path="/:id/edit" element={<JurisprudencesEdit />} />
              </PrivateRoute>

              <PrivateRoute path="/comments" element={<CommentsIndex />} />

            </Routes>
          </Layout>
        </Router>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
