import React from 'react';
import { useNavigate } from 'react-router-dom';
import { UsersForm } from 'Components';

const UsersCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <UsersForm
        onSubmit={() => navigate('/users')}
      />
    </section>
  );
};

export default UsersCreate;
