import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTermQuery } from 'GraphQL/queries/settings/term.graphql';
import { TermsForm, Loading } from 'Components';

const TermsEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useTermQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return loading
    ? <Loading center pad />
    : (
      <TermsForm
        term={data?.term}
        onDelete={() => navigate('/terms')}
      />
    );
};

export default TermsEdit;
