import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCompanyQuery } from 'GraphQL/queries/company.graphql';
import { CompaniesForm, Loading } from 'Components';

const CompaniesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useCompanyQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <CompaniesForm
            company={data?.company}
            onDelete={() => navigate('/companies')}
          />
        )}
    </section>
  );
};

export default CompaniesEdit;
