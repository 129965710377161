export const readToken = (key = 'token') => {
  const token = localStorage.getItem(key);
  if (!token) return null;

  try {
    const { exp } = JSON.parse(window.atob(token.split('.')[1]));
    if (new Date().getTime() / 1000 > exp) return null;

    return token;
  } catch {
    return null;
  }
};

export const writeToken = (token?: string | null, key = 'token') => {
  if (token) {
    localStorage.setItem(key, token);
  } else {
    localStorage.removeItem(key);
  }
};
