import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsForm } from 'Components';
import { useCreateNewsCategoryMutation } from 'GraphQL/mutations/settings/createNewsCategory.graphql';

const NewsCategoriesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <SettingsForm
        entity="Nieuwscategorie"
        cacheKey="newsCategories"
        onSubmit={() => navigate('/news-categories')}
        createHook={useCreateNewsCategoryMutation}
      />
    </section>
  );
};

export default NewsCategoriesCreate;
