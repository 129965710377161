import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  Button,
  Layout,
  notification,
  Tree,
} from 'antd';
import { PlusOutlined, PartitionOutlined } from '@ant-design/icons';
import { usePoliciesQuery } from 'GraphQL/queries/policies.graphql';
import { useUpdatePolicyPositionMutation } from 'GraphQL/mutations/updatePolicyPosition.graphql';
import { invalidateCache } from 'Common/invalidateCache';
import { Loading } from 'Components';
import styles from 'Components/ResourceIndex/ResourceIndex.module.css';
import useAuth from 'Hooks/useAuth';
import generalTreeStyles from '../DocumentTexts/DocumentTextsIndex.module.css';

const PoliciesIndex = () => {
  const { isAdmin } = useAuth();

  const { data } = usePoliciesQuery({
    fetchPolicy: 'network-only',
  });
  const [updatePolicyPositionMutation] = useUpdatePolicyPositionMutation();

  const onDrop = async ({
    dragNode, dropToGap, node, dropPosition,
  }: any) => {
    const correctionForOwnPosition = (dragNode.parentId === node.parentId && dragNode.sortOrder < dropPosition) ? 1 : 0;
    try {
      await updatePolicyPositionMutation({
        variables: {
          data: {
            id: dragNode.key,
            parentId: dropToGap ? node.parentId : node.key,
            sortOrder: dropToGap ? dropPosition - correctionForOwnPosition : 0,
          },
        },
        ...invalidateCache('policies'),
      });
    } catch (e) {
      notification.error({ message: 'Verplaatsen van beleidsitem is mislukt' });
    }
  };

  return (
    <Layout>
      <Layout.Sider className="resource-index" width={390}>
        <div className={styles.resourceIndex}>
          <header>
            <div className={styles.icon}><PartitionOutlined /></div>
            <h3>Control framework</h3>
            {isAdmin && (
              <Link to="/policies/new">
                <Button
                  type="primary"
                  htmlType="button"
                >
                  <PlusOutlined />
                </Button>
              </Link>
            )}
          </header>
          <main
            className={generalTreeStyles.tree}
            id="scrollableDiv"
            style={{ overflowY: 'scroll', height: '100%' }}
          >
            {!data ? (
              <Loading center pad />
            ) : (
              <Tree
                treeData={data?.policies || []}
                draggable={{ icon: false }}
                onDrop={onDrop}
                blockNode
                titleRender={(e: any) => (
                  <Link
                    style={{ display: 'block' }}
                    to={`/policies/${e.key}/edit`}
                  >
                    {`${e.code} ${e.name}`}
                  </Link>
                )}
              />
            )}
          </main>
        </div>
      </Layout.Sider>
      <Layout.Content className="resource-content">
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default PoliciesIndex;
