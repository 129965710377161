import React from 'react';
import { Space } from 'antd';

interface ButtonsBarProps {
  children: React.ReactNode
}

const ButtonsBar = ({ children }: ButtonsBarProps) => (
  <Space
    style={{
      bottom: 10,
      right: 10,
      position: 'fixed',
      zIndex: 100,
    }}
  >
    {children}
  </Space>
);

export default ButtonsBar;
