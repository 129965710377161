import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { PushpinOutlined } from '@ant-design/icons';
import { useNewsCategoriesQuery, SortOrder, QueryMode } from 'GraphQL/queries/settings/newsCategories.graphql';
import { ResourceIndex } from 'Components';

const NewsCategorysIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useNewsCategoriesQuery}
        rootKey="newsCategories"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        search={{
          toWhere: (search) => {
            if (!search) return {};
            return { name: { contains: search, mode: QueryMode.Insensitive } };
          },
        }}
        createHref="/news-categories/new"
        title="Nieuwscategorieën"
        icon={<PushpinOutlined />}
      >
        {(newsCategory) => (
          <tr key={newsCategory.id}>
            <td>
              <NavLink to={`/news-categories/${newsCategory.id}/edit`}>{newsCategory.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default NewsCategorysIndex;
