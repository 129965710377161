import React from 'react';
import { TreeSelect } from 'antd';

const { TreeNode } = TreeSelect;

type DocumentTextsTree = {
  key: string,
  title?: string | null,
  id: number,
  children?: DocumentTextsTree[],
  interpretations?: {
    id: number,
    sortId: string,
    productResult?: string | null,
  }[] | null,
};

interface InterpretationSelectProps {
  documentTexts?: DocumentTextsTree[] | null,
  ownId: number,
}

const renderDocumentTextInterpretations = (documentTextsTree: DocumentTextsTree[], ownId: number) => documentTextsTree.map((d) => (
  <TreeNode disabled key={100000000000 + d.key} value={100000000000 + d.key} title={`${d.key} ${d.title}`} name={d.title}>
    {d.interpretations && d.interpretations.map((i) => (
      <TreeNode
        disabled={ownId === i.id}
        key={i.id}
        value={i.id}
        title={`${d.key} ${i.sortId} - ${i.productResult || ''} ${ownId === i.id ? '(Huidige interpretatie)' : ''}`}
        name={i.productResult}
      />
    ))}
    {d.children && renderDocumentTextInterpretations(d.children, ownId)}
  </TreeNode>
));

const InterpretationSelect = ({
  documentTexts, ownId, ...rest
}: InterpretationSelectProps) => (
  <TreeSelect
    {...rest} // eslint-disable-line react/jsx-props-no-spreading
    multiple
    showSearch
    style={{ width: '100%' }}
    dropdownStyle={{ height: 'auto', overflow: 'auto' }}
    placeholder="Selecteer interpretaties waarop de huidige afhankelijkheid heeft"
    allowClear
    filterTreeNode={(searchValue, node) => new RegExp(searchValue, 'gi').test(node?.name)}
  >
    {documentTexts && renderDocumentTextInterpretations(documentTexts, ownId)}
  </TreeSelect>
);

export default InterpretationSelect;
