import { FieldPolicy } from '@apollo/client';

type KeyArgs = FieldPolicy<any>['keyArgs'];

const offsetLimitPagination = (keyArgs: KeyArgs = false) => ({
  keyArgs,
  merge(existing: any, incoming: any, { args }: any) {
    if (!existing) return incoming;

    const merged = existing.slice(0) || [];

    const { skip = 0 } = args;
    for (let i = 0; i < incoming.length; ++i) {
      merged[skip + i] = incoming[i];
    }

    return merged;
  },
});

export default offsetLimitPagination;
