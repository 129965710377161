import React from 'react';
import {
  Button,
  Form,
  Input,
  notification,
} from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { useAddCommentMutation } from '../../GraphQL/mutations/addComment.graphql';
import { invalidateCache } from '../../Common/invalidateCache';

interface CommentFormProps {
  documentTextId: number
}

interface FormData {
  text: string
}

const CommentForm = ({ documentTextId }: CommentFormProps) => {
  const [form] = Form.useForm();
  const [addComment] = useAddCommentMutation({
    ...invalidateCache('documentText'),
  });

  const handleSubmit = async (formData: FormData) => {
    try {
      await addComment({
        variables: {
          ...formData,
          documentTextId,
        },
      });
      form.resetFields();
    } catch (error) {
      notification.success({ message: 'Bericht kon niet worden verstuurd' });
    }
  };

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
    >
      <Form.Item
        name="text"
        rules={[{ required: true }]}
      >
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
      </Form.Item>

      <Form.Item>
        <Button
          onClick={form.submit}
          icon={<SendOutlined />}
        >
          Verstuur
        </Button>
      </Form.Item>
    </Form>

  );
};

export default CommentForm;
