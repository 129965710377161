import React, { useMemo, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Layout as LayoutAnt, Menu } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  BankOutlined,
  BulbOutlined,
  FileProtectOutlined,
  HistoryOutlined,
  HourglassOutlined,
  InboxOutlined,
  LogoutOutlined,
  PartitionOutlined,
  ProfileOutlined,
  PushpinOutlined,
  ReadOutlined,
  SecurityScanOutlined,
  TagsOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import useAuth from 'Hooks/useAuth';

declare global {
  interface Window {
    Userback:any;
  }
}

const { Content, Sider } = LayoutAnt;

export interface LayoutProps {
  className?: string,
  children: React.ReactNode,
}

const Layout = ({ className, children }: LayoutProps) => {
  const {
    isAuthenticated, hasRole, currentUser, logout,
  } = useAuth();
  const [collapsed, setCollapsed] = useState(true);

  const location = useLocation();
  const selectedKeys = useMemo(() => (
    [location.pathname.split('/')[1]]
  ), [location]);

  useEffect(() => {
    if (hasRole('ADMIN') && window.Userback.access_token) {
      window.Userback.email = currentUser?.email;
      window.Userback.name = [currentUser?.firstName, currentUser?.lastNamePrefix, currentUser?.lastName].filter(Boolean).join(' ');
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://static.userback.io/widget/v1.js';
      (document.head || document.body).appendChild(script);
    }
  }, [isAuthenticated]);

  return (
    <LayoutAnt
      className={className}
      style={{
        minHeight: '100vh',
        minWidth: 720,
        ...(isAuthenticated ? { padding: 10 } : {}),
      }}
    >
      {isAuthenticated ? (
        <OutsideClickHandler onOutsideClick={() => setCollapsed(true)}>
          <Sider
            collapsedWidth="0"
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={240}
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 3,
            }}
          >
            <Menu
              theme="dark"
              mode="vertical"
              selectedKeys={selectedKeys}
              style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
              items={[
                {
                  type: 'group',
                  label: 'Algemeen',
                },
                {
                  key: 'documents',
                  label: <NavLink to="/documents" onClick={() => setCollapsed(true)}>Wet &amp; Regelgeving</NavLink>,
                  icon: <ReadOutlined />,
                },
                {
                  icon: <PartitionOutlined />,
                  key: 'policies',
                  label: <NavLink to="/policies" onClick={() => setCollapsed(true)}>Control framework</NavLink>,
                },
                {
                  icon: <BankOutlined />,
                  key: 'companies',
                  label: <NavLink to="/companies" onClick={() => setCollapsed(true)}>Organisaties</NavLink>,
                },
                {
                  icon: <InboxOutlined />,
                  key: 'news-messages',
                  label: <NavLink to="/news-messages" onClick={() => setCollapsed(true)}>Nieuwsberichten</NavLink>,
                },
                {
                  icon: <ProfileOutlined />,
                  key: 'terms',
                  label: <NavLink to="/terms" onClick={() => setCollapsed(true)}>Begrippen</NavLink>,
                },
                {
                  icon: <SecurityScanOutlined />,
                  key: 'jurisprudences',
                  label: <NavLink to="/jurisprudences" onClick={() => setCollapsed(true)}>Jurisprudentie</NavLink>,
                },
                {
                  type: 'group',
                  label: 'Instellingen',
                },
                {
                  icon: <FileProtectOutlined />,
                  key: 'document-types',
                  label: <NavLink to="/document-types" onClick={() => setCollapsed(true)}>W&amp;R-types</NavLink>,
                },
                {
                  icon: <HourglassOutlined />,
                  key: 'eunomea-states',
                  label: <NavLink to="/eunomea-states" onClick={() => setCollapsed(true)}>W&amp;R-Eunomea status</NavLink>,
                },
                {
                  icon: <HistoryOutlined />,
                  key: 'lifecycle-states',
                  label: <NavLink to="/lifecycle-states" onClick={() => setCollapsed(true)}>W&amp;R-levenscycles status</NavLink>,
                },
                {
                  icon: <BulbOutlined />,
                  key: 'text-interpretation-states',
                  label: <NavLink to="/text-interpretation-states" onClick={() => setCollapsed(true)}>Interpretatie status</NavLink>,
                },
                {
                  icon: <TagsOutlined />,
                  key: 'application-characteristics',
                  label: <NavLink to="/application-characteristics" onClick={() => setCollapsed(true)}>Toepassingskenmerken</NavLink>,
                },
                {
                  icon: <PushpinOutlined />,
                  key: 'news-categories',
                  label: <NavLink to="/news-categories" onClick={() => setCollapsed(true)}>Nieuwscategorieën</NavLink>,
                },
                {
                  icon: <TeamOutlined />,
                  key: 'users',
                  label: <NavLink to="/users" onClick={() => setCollapsed(true)}>Gebruikers</NavLink>,
                },
                {
                  type: 'divider',
                  style: { borderColor: 'transparent', flex: 1 },
                },
                {
                  key: 'version',
                  style: {
                    bottom: -5,
                    position: 'absolute',
                    fontSize: '85%',
                    opacity: 0.5,
                    right: 0,
                    textAlign: 'right',
                  },
                  label: process.env.REACT_APP_VERSION,
                },
                {
                  icon: <LogoutOutlined />,
                  key: 'logout',
                  label: 'Uitloggen',
                  onClick: () => logout(),
                },
              ]}
            />
          </Sider>
        </OutsideClickHandler>
      ) : null}
      <Content>
        {children}
      </Content>
    </LayoutAnt>
  );
};

export default Layout;
