import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDocumentQuery } from 'GraphQL/queries/document.graphql';
import { DocumentsForm, Loading } from 'Components';

const DocumentsEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useDocumentQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <DocumentsForm
            document={data?.document}
            onDelete={() => navigate('/documents')}
          />
        )}
    </section>
  );
};

export default DocumentsEdit;
