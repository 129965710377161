import React from 'react';
import classNames from 'classnames/bind';
import styles from './Container.module.css';

const cx = classNames.bind(styles);

export interface ContainerProps {
  children: React.ReactNode,
  noPadding?: boolean,
}

const Container = ({ children, noPadding }: ContainerProps) => (
  <div className={cx('container', { noPadding })}>
    {children}
  </div>
);

export default Container;
