import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { readToken } from 'Common/token';
import offsetLimitPagination from 'Common/pagination';

const httpUploadLink = createUploadLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = readToken();
  const deviceToken = readToken('device');

  return {
    headers: {
      ...headers,
      'apollo-require-preflight': 'true',
      'apollo-client-name': 'web',
      'x-apollo-operation-name': 'web',
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      ...(deviceToken ? { 'X-Device-Token': deviceToken } : {}),
    },
  };
});

const client = new ApolloClient({
  // @ts-ignore
  link: authLink.concat(httpUploadLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'network-only',
      errorPolicy: 'none',
    },
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          companies: offsetLimitPagination(['where', 'orderBy']),
          documents: offsetLimitPagination(['where', 'orderBy']),
          documentTypes: offsetLimitPagination(['where', 'orderBy']),
          eunomeaStates: offsetLimitPagination(['where', 'orderBy']),
          employees: offsetLimitPagination(['where', 'orderBy']),
          jurisprudences: offsetLimitPagination(['where', 'orderBy']),
          lifecyleStates: offsetLimitPagination(['where', 'orderBy']),
          newsMessages: offsetLimitPagination(['where', 'orderBy']),
          terms: offsetLimitPagination(['where', 'orderBy']),
          textInterpretationStates: offsetLimitPagination(['where', 'orderBy']),
          users: offsetLimitPagination(['where', 'orderBy']),
        },
      },
    },
  }),
});

export default client;
