import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePolicyQuery } from 'GraphQL/queries/policy.graphql';
import { PoliciesForm, Loading } from 'Components';

const PoliciesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = usePolicyQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return loading
    ? <section><Loading center pad /></section>
    : (
      <PoliciesForm
        policy={data?.policy}
        onDelete={() => navigate('/policies')}
      />
    );
};

export default PoliciesEdit;
