import React from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentTextQuery } from 'GraphQL/queries/documentText.graphql';
import { DocumentTextsForm, Loading } from 'Components';

const DocumentTextsEdit = () => {
  const { id } = useParams();

  const { data, loading } = useDocumentTextQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
  });

  return loading
    ? <section><Loading center pad /></section>
    : <DocumentTextsForm documentText={data?.documentText} />;
};

export default DocumentTextsEdit;
