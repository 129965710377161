import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JurisprudencesForm, Loading } from 'Components';
import { useJurisprudenceQuery } from 'GraphQL/queries/jurisprudence.graphql';

const JurisprudencesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useJurisprudenceQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <JurisprudencesForm
            jurisprudence={data?.jurisprudence}
            onDelete={() => navigate('/jurisprudences')}
          />
        )}
    </section>
  );
};

export default JurisprudencesEdit;
