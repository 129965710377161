import React from 'react';
import { useNavigate } from 'react-router-dom';
import JurisprudencesForm from 'Components/JurisprudencesForm/JurisprudencesForm';

const JurisprudencesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <JurisprudencesForm
        onSubmit={() => navigate('/jurisprudences')}
      />
    </section>
  );
};

export default JurisprudencesCreate;
