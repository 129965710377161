import React, { useEffect, useMemo, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
} from 'antd';
import QRCode from 'qrcode.react';
import { LoginOutlined } from '@ant-design/icons';
import useAuth from 'Hooks/useAuth';
import { VALIDATE_MESSAGES } from 'Common/translations';
import logo from 'Assets/logo.png';
import { useTotpSetupMutation } from 'GraphQL/mutations/totpSetup.graphql';
import { Loading } from 'Components';
import styles from './Login.module.css';

interface FormData {
  code: string,
}

const generateQrValue = (email?: string, key?: string): string => (`otpauth://totp/${email}?secret=${key}&period=30&digits=6&algorithm=SHA1&issuer=Eunomea`);

const TotpEnableView = () => {
  const { totpEnable, totpEnableLoading, totpEnableError } = useAuth();
  const [setupMutation, { loading }] = useTotpSetupMutation();
  const [setup, setSetup] = useState<{ email: string, secondFactorKey: string } | undefined>(undefined);

  const handleSubmit = async (formData: FormData) => {
    try {
      await totpEnable({ variables: formData });
    } catch (error) {
      // error is rendered with loginError
    }
  };

  const hasError = useMemo(() => totpEnableError?.graphQLErrors, [totpEnableError]);

  useEffect(() => {
    if (!setup) {
      setupMutation().then(({ data }) => {
        setSetup(data?.totpSetup);
      });
    }
  });

  return (
    <div className={styles.login}>
      <Form
        onFinish={handleSubmit}
        validateMessages={VALIDATE_MESSAGES}
        layout="vertical"
      >
        <img
          className={styles.logo}
          src={logo}
          alt="Eunomea"
        />

        {hasError && (
        <Alert
          type="error"
          message="U hebt een ongeldige verificatie code ingevoerd"
          style={{ marginBottom: 18, fontSize: 13 }}
        />
        )}

        <h2>Tweestapsverificatie instellen</h2>

        {(loading || !setup)
          ? <Loading center pad />
          : (
            <>
              <p>Scan onderstaande code in een verificatie-app zoals Google Authenticator.</p>

              <Row justify="center" style={{ paddingBottom: 10 }}>
                <QRCode value={generateQrValue(setup?.email, setup?.secondFactorKey)} />
              </Row>

              <p>Voer de code uit uw verificatie-app in.</p>

              <Form.Item
                name="code"
                rules={[{ required: true }]}
              >
                <Input
                  autoComplete="one-time-code"
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  block
                  htmlType="submit"
                  icon={<LoginOutlined />}
                  loading={totpEnableLoading}
                  type="primary"
                >
                  Verifiëren
                </Button>
              </Form.Item>
            </>
          )}
        <p>Ongeauthoriseerde toegang is verboden</p>
      </Form>
    </div>
  );
};

export default TotpEnableView;
