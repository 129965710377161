import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import style from './RichText.module.css';

const RichText = (props: typeof ReactQuill) => (
  <ReactQuill
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    className={style.quill}
    modules={{
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: 'red' }, { color: 'black' }],
        [{ background: 'yellow' }, { background: 'white' }],
        ['link'],
      ],
    }}
  />
);

export default RichText;
