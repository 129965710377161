import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  center?: boolean
  pad?: boolean
  spinning?: boolean
  children?: React.ReactNode
}

const Loading = ({
  center, pad, spinning, children,
}: Props) => (
  <div style={{
    display: 'flex',
    ...center ? { justifyContent: 'center' } : {},
    ...pad ? { margin: '20px 0' } : {},
  }}
  >
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
      spinning={spinning}
    >
      {children}
    </Spin>
  </div>
);

export default Loading;
