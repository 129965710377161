import React from 'react';
import { Outlet, NavLink, useParams } from 'react-router-dom';
import { Layout } from 'antd';
import { useEmployeesQuery, SortOrder, QueryMode } from 'GraphQL/queries/employees.graphql';
import { ResourceIndex } from 'Components';

const EmployeesIndex = () => {
  const { companyId } = useParams();

  return (
    <Layout>
      <Layout.Sider className="resource-index" width={390}>
        <ResourceIndex
          query={useEmployeesQuery}
          rootKey="employees"
          defaultScope={{
            orderBy: { lastName: SortOrder.Asc },
          }}
          search={{
            toWhere: (search) => {
              if (!search) return { companyId: Number.parseInt(companyId, 10) };
              return {
                companyId: Number.parseInt(companyId, 10),
                lastName: { contains: search, mode: QueryMode.Insensitive },
              };
            },
          }}
          createHref={`/companies/${companyId}/employees/new`}
          backButtonHref={`/companies/${companyId}/edit`}
          title="Medewerkers"
        >
          {(employee) => (
            <tr key={employee.id}>
              <td>
                <NavLink to={`/companies/${companyId}/employees/${employee.id}/edit`}>
                  {`${employee.firstName} ${employee.lastNamePrefix || ''} ${employee.lastName}`}
                </NavLink>
              </td>
            </tr>
          )}
        </ResourceIndex>
      </Layout.Sider>
      <Layout.Content className="resource-content">
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default EmployeesIndex;
