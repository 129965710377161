import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Typography, Layout } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import { ResourceIndex } from 'Components';
import { useNewsMessagesQuery, SortOrder } from 'GraphQL/queries/newsMessages.graphql';
import dayjs from 'dayjs';

const NewsMessagesIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useNewsMessagesQuery}
        defaultScope={{
          orderBy: [
            { published: SortOrder.Desc },
            { subject: SortOrder.Asc },
          ],
        }}
        rootKey="newsMessages"
        createHref="/news-messages/new"
        createRoles={['ADMIN', 'NEWS_EDITOR']}
        title="Nieuwsberichten"
        icon={<ReadOutlined />}
      >
        {({ id, subject, published }) => (
          <tr key={id}>
            <td>
              <NavLink to={`/news-messages/${id}/edit`}>
                <Typography.Text ellipsis style={{ width: 350 }}>
                  <span style={{ opacity: 0.6, marginRight: 5 }}>
                    {dayjs(published).format('DD-MM-YYYY')}
                  </span>
                  {subject}
                </Typography.Text>
              </NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default NewsMessagesIndex;
