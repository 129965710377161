import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { useLifecycleStatesQuery, SortOrder } from 'GraphQL/queries/settings/lifecycleStates.graphql';
import { ResourceIndex } from 'Components';

const LifecycleStatesIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useLifecycleStatesQuery}
        rootKey="lifecycleStates"
        defaultScope={{
          orderBy: { name: SortOrder.Asc },
        }}
        createHref="/lifecycle-states/new"
        title="W&amp;R-levenscyclus status"
        icon={<HistoryOutlined />}
      >
        {(lifecycleState) => (
          <tr key={lifecycleState.id}>
            <td>
              <NavLink to={`/lifecycle-states/${lifecycleState.id}/edit`}>{lifecycleState.name}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default LifecycleStatesIndex;
