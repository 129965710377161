import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
} from 'antd';
import { CheckOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonsBar } from 'Components';
import { useCreateTermMutation } from 'GraphQL/mutations/settings/createTerm.graphql';
import { useUpdateTermMutation } from 'GraphQL/mutations/settings/updateTerm.graphql';
import { useDeleteTermMutation } from 'GraphQL/mutations/settings/deleteTerm.graphql';
import { useDeleteDescriptionMutation } from 'GraphQL/mutations/settings/deleteDescription.graphql';
import { VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import { toGraphql } from 'Common/graphqlTransform';
import useAuth from 'Hooks/useAuth';
import style from './TermsForm.module.css';

type Description = {
  id: number
  text: string
  source: string
};

type Term = {
  id: number
  name: string
  descriptions?: Description[] | null
};

interface TermsFormProps {
  term?: Term | null,
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

interface FormData {
  name: string
  eunomeaDescription?: string
  descriptions?: {
    id?: number
    text: string
    source: string
  }[]
}

const transforms = {
  hasMany: ['descriptions'],
};

const TermsForm: FC<TermsFormProps> = ({ term, onSubmit, onDelete }: TermsFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [descriptionDeleteModal, setDescriptionDeleteModal] = useState<number>(-1);
  const [form] = Form.useForm();
  const { hasRole } = useAuth();

  const [createTermMutation, { loading: loadingCreate }] = useCreateTermMutation({ ...invalidateCache('terms') });
  const [updateTermMutation, { loading: loadingUpdate }] = useUpdateTermMutation();
  const [deleteTermMutation] = useDeleteTermMutation({ ...invalidateCache('terms') });
  const [deleteDescriptionMutation] = useDeleteDescriptionMutation();

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createTermMutation({
        variables: {
          data: {
            ...toGraphql(formData, transforms, 'CREATE'),
            descriptions: { create: formData.descriptions },
          },
        },
      });
      notification.success({ message: 'Begrip is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Begrip toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    const created = (formData.descriptions || []).filter((description) => !description.id);

    const updated = (formData.descriptions || [])
      .filter((description) => description.id)
      .map(({ id, text, source }) => ({
        data: { text, source },
        where: { id },
      }));

    try {
      setIsBlocking(false);
      const { data } = await updateTermMutation({
        variables: {
          data: {
            ...toGraphql(formData, transforms, 'UPDATE'),
            descriptions: {
              create: created,
              update: updated,
            },
          },
          id: term!.id,
        },
      });
      notification.success({ message: 'Begrip is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Begrip opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteTermMutation({
        variables: { id: term!.id },
      });
      notification.success({ message: 'Begrip is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Begrip verwijderen mislukt' });
    }
  };

  const removeField = (key: number) => {
    const descriptions = form.getFieldValue('descriptions');
    setDescriptionDeleteModal(-1);
    if (!descriptions.length) return;

    const descriptionId = descriptions[key]?.id;

    if (descriptionId) {
      try {
        setIsBlocking(false);
        if (descriptions[key].id) {
          deleteDescriptionMutation({
            variables: {
              id: descriptions[key].id,
            },
          });
        }
        notification.success({ message: 'De beschrijving is verwijderd' });
      } catch (e) {
        notification.success({ message: 'De beschrijving kan niet worden verwijderd' });
      }
    }

    form.setFieldsValue({
      descriptions: [...descriptions.slice(0, key), ...descriptions.slice(key + 1)],
    });
  };

  useEffect(() => form.resetFields(), [term]);

  return (
    <>
      <div className={style.cover} />
      <Form
        className={style.formWithSections}
        form={form}
        layout="vertical"
        initialValues={term || undefined}
        onFinish={term ? handleUpdate : handleCreate}
        validateMessages={VALIDATE_MESSAGES}
        onFieldsChange={() => setIsBlocking(true)}
      >
        <Prompt
          when={isBlocking && hasRole('ADMIN', 'TERMS_EDITOR')}
          message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
        />

        <section>
          <Form.Item
            label="Begrip"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Eunomea uitleg"
            name="eunomeaDescription"
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
          </Form.Item>
        </section>

        <Form.List name="descriptions">
          {(fields: { key: number, name: number, fieldKey: number }[], { add }) => (
            <>
              { fields.map(({ key, name, fieldKey }) => (
                <section key={key}>
                  <Row>
                    <Col flex={1}>
                      <Form.Item
                        label="Bron"
                        name={[name, 'source']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {hasRole('ADMIN', 'TERMS_EDITOR') && (
                      <Button
                        onClick={() => setDescriptionDeleteModal(key)}
                        danger
                        icon={<DeleteOutlined />}
                        style={{ marginTop: 22, marginLeft: 10 }}
                      />
                    )}
                  </Row>
                  <Form.Item
                    label="Uitleg"
                    name={[name, 'text']}
                    fieldKey={[fieldKey, 'text']}
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
                  </Form.Item>
                </section>
              ))}
              {hasRole('ADMIN', 'TERMS_EDITOR') && (
                <Button
                  onClick={() => add()}
                  type="link"
                  icon={<PlusOutlined />}
                >
                  Beschrijving toevoegen
                </Button>
              )}
            </>
          )}
        </Form.List>

        <Modal
          onCancel={() => setDescriptionDeleteModal(-1)}
          title="Bevestig uw keuze"
          visible={descriptionDeleteModal > -1}
          cancelText="Annuleren"
          okText="Ik weet het zeker"
          onOk={() => removeField(descriptionDeleteModal)}
          okButtonProps={{ danger: true }}
        >
          <p>Weet u zeker dat u deze beschrijving wilt verwijderen?</p>
        </Modal>

        {hasRole('ADMIN', 'TERMS_EDITOR') && (
          <ButtonsBar>
            {term && (
              <>
                <Button danger type="primary" onClick={() => setDeleteModal(true)}>
                  <DeleteOutlined />
                  Verwijderen
                </Button>
                <Modal
                  onCancel={() => setDeleteModal(false)}
                  title="Bevestig uw keuze"
                  visible={deleteModal}
                  cancelText="Annuleren"
                  okText="Ik weet het zeker"
                  onOk={handleDelete}
                  okButtonProps={{ danger: true }}
                >
                  {`Weet u zeker dat u het begrip ${term?.name} wilt verwijderen?`}
                </Modal>
              </>
            )}
            <Button
              type="primary"
              htmlType="submit"
              loading={loadingCreate || loadingUpdate}
            >
              <CheckOutlined />
              Opslaan
            </Button>
          </ButtonsBar>
        )}
      </Form>
    </>
  );
};

export default TermsForm;
