import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { QueryHookOptions, QueryResult } from '@apollo/client';
import { Input, Button } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Loading } from 'Components';
import useAuth from 'Hooks/useAuth';
import styles from './ResourceIndex.module.css';

interface IndexVariables {
  where?: any
  orderBy?: any
  take?: number | null | undefined
  skip?: number | null | undefined
}

type IndexWhere<V extends IndexVariables> = V['where'];

type IndexResultPropertyName<Q> = {
  [K in keyof Q]: NonNullable<NonNullable<Q>[K]> extends unknown[] ? K : never
}[keyof Q];

type IndexResultType<Q> = {
  [K in keyof Q]: Q[K] extends Array<infer T> ? T : never
}[keyof Q];

type IndexQuery<Q, V extends IndexVariables> = (options: QueryHookOptions<Q, V>) => QueryResult<Q, any>;

type ToWhere<I, Q> = (input: I) => Partial<Q>;
interface Search<Q> {
  toWhere: ToWhere<string, Q>
}

interface ResourceIndexProps<Q, V extends IndexVariables, R extends IndexResultPropertyName<Q>> {
  query: IndexQuery<Q, V>
  rootKey: R,
  defaultScope?: V,
  search?: Search<NonNullable<IndexWhere<V>>>
  searchParameter?: string
  title: string
  createHref: string
  createRoles?: string[]
  icon?: React.ReactNode
  backButtonHref?: string
  children: (child: NonNullable<IndexResultType<Q>>) => React.ReactNode
}

const ResourceIndex = <Q, V extends IndexVariables, R extends IndexResultPropertyName<Q>>({
  query,
  rootKey,
  defaultScope,
  search,
  searchParameter = 'naam',
  title,
  createHref,
  createRoles = ['ADMIN'],
  icon,
  backButtonHref,
  children,
}: ResourceIndexProps<Q, V, R>) => {
  const [searchValue, setSearchValue] = useState('');
  const { hasRole } = useAuth();

  const variables = useMemo(() => ({
    ...(defaultScope || {}),
    ...(search ? { where: search.toWhere(searchValue) } : {}),
  }), [defaultScope, search, searchValue]);

  const { data, fetchMore, loading } = query({
    variables: {
      skip: 0,
      take: 25,
      ...variables,
    } as V,
    fetchPolicy: 'network-only',
  });

  const records = useMemo(() => (
    data?.[rootKey] as Array<IndexResultType<Q>> || []
  ), [data]);

  const hasMore = useMemo(() => (
    records.length % 25 === 0
  ), [records]);

  const onScroll = () => {
    fetchMore({ variables: { skip: records.length } });
  };

  const initialLoading = useMemo(() => (
    loading && !data
  ), [loading, data]);

  const noRecords = useMemo(() => (
    !loading
    && searchValue === ''
    && records.length === 0
  ), [loading, searchValue, records]);

  const noSearchResults = useMemo(() => (
    !loading
    && searchValue !== ''
    && records.length === 0
  ), [loading, searchValue, records]);

  return (
    <div className={styles.resourceIndex}>
      <header>
        {backButtonHref && (
        <Link to={backButtonHref}>
          <div className={styles.icon}><ArrowLeftOutlined /></div>
        </Link>
        )}
        {icon && <div className={styles.icon}>{icon}</div>}
        <h3>
          {title}
        </h3>
        {hasRole(...createRoles) && (
          <Link to={createHref}>
            <Button
              type="primary"
              htmlType="button"
            >
              <PlusOutlined />
            </Button>
          </Link>
        )}
        {search && (
          <Input
            placeholder={`Zoek op ${searchParameter}...`}
            style={{ width: '100%' }}
            suffix={<SearchOutlined />}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        )}
      </header>
      <main
        id="scrollableDiv"
        style={{ overflowY: 'scroll', height: '100%' }}
      >
        <InfiniteScroll
          dataLength={records.length}
          next={onScroll}
          hasMore={hasMore}
          loader={null}
          scrollableTarget="scrollableDiv"
        >
          {initialLoading && <Loading center pad />}
          {noRecords && <p className={styles.message}>Er zijn nog geen items.</p>}
          {noSearchResults && <p className={styles.message}>Geen resultaten gevonden.</p>}
          {records.length > 0 && (
          <table>
            <tbody>
              {records.map((record: IndexResultType<Q>) => (records ? children(record!) : null))}
            </tbody>
          </table>
          )}
        </InfiniteScroll>
      </main>
    </div>
  );
};

export default ResourceIndex;
