import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsForm } from 'Components';
import { useCreateApplicationCharacteristicMutation } from 'GraphQL/mutations/settings/createApplicationCharacteristic.graphql';

const ApplicationCharacteristicsCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <SettingsForm
        entity="Toepassingskenmerk"
        nameLabel="Toepassingskenmerk"
        descriptionLabel="Toelichting"
        cacheKey="applicationCharacteristics"
        onSubmit={() => navigate('/application-characteristics')}
        createHook={useCreateApplicationCharacteristicMutation}
      />
    </section>
  );
};

export default ApplicationCharacteristicsCreate;
