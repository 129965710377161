import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDocumentTypeQuery } from 'GraphQL/queries/settings/documentType.graphql';
import { useUpdateDocumentTypeMutation } from 'GraphQL/mutations/settings/updateDocumentType.graphql';
import { useDeleteDocumentTypeMutation } from 'GraphQL/mutations/settings/deleteDocumentType.graphql';
import { Loading, SettingsForm } from 'Components';

const DocumentTypesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useDocumentTypeQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <SettingsForm
            entity="W&amp;R-type"
            cacheKey="documentTypes"
            settingsState={data?.documentType}
            onDelete={() => navigate('/document-types')}
            updateHook={useUpdateDocumentTypeMutation}
            deleteHook={useDeleteDocumentTypeMutation}
          />
        )}
    </section>
  );
};

export default DocumentTypesEdit;
