import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TermsForm } from 'Components';

const TermsCreate = () => {
  const navigate = useNavigate();

  return (
    <TermsForm
      onSubmit={() => navigate('/terms')}
    />
  );
};

export default TermsCreate;
