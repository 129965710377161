import React from 'react';
import {
  Outlet,
  useParams,
  Link,
} from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
  Tree,
} from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import classNames from 'classnames/bind';
import { useDocumentQuery } from 'GraphQL/queries/document.graphql';
import { useDocumentTextsQuery } from 'GraphQL/queries/documentTexts.graphql';
import styles from 'Components/ResourceIndex/ResourceIndex.module.css';
import { Loading } from 'Components';
import Dot from 'Components/ResourceIndex/Dot';
import treeStyles from './DocumentTextsIndex.module.css';

const cx = classNames.bind(treeStyles);

const DocumentTextsIndex = () => {
  const { documentId } = useParams();
  const { data: document } = useDocumentQuery({
    variables: {
      id: Number.parseInt(documentId, 10),
    },
    fetchPolicy: 'network-only',
  });
  const { data, loading } = useDocumentTextsQuery({
    variables: {
      documentId: Number.parseInt(documentId, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Layout>
      <Layout.Sider className="resource-index" width={390}>
        <div className={styles.resourceIndex}>
          <Link to={`/documents/${documentId}/edit`} className={styles.header}>
            <div className={styles.icon}><ArrowLeftOutlined /></div>
            {document && <h3>{document.document?.shortName}</h3>}
          </Link>
          <main
            className={cx('tree', 'alternate')}
            id="scrollableDiv"
            style={{ overflowY: 'scroll', height: '100%' }}
          >
            {loading && <Loading center pad />}
            {data?.documentTexts?.length === 0 && <p className={styles.message}>Er zijn nog een teksten ingelezen.</p>}
            {data && data.documentTexts.length > 0 && (
              <Tree
                treeData={data.documentTexts}
                blockNode
                titleRender={(item: any) => (
                  <Link
                    style={{ display: 'block' }}
                    to={`/documents/${documentId}/document-texts/${item.id}/edit`}
                  >
                    <Row wrap={false}>
                      <Col flex={1}>
                        {`${item.key} ${item.title}`}
                      </Col>
                      <Col>
                        <Dot title={item.state.name} />
                      </Col>
                    </Row>
                  </Link>
                )}
              />
            )}
          </main>
        </div>
      </Layout.Sider>
      <Layout.Content className="resource-content">
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default DocumentTextsIndex;
