import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsForm } from 'Components';
import { useCreateLifecycleStateMutation } from 'GraphQL/mutations/settings/createLifecycleState.graphql';

const LifecycleStatesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <SettingsForm
        entity="W&R-levenscyclus status"
        cacheKey="lifecycleStates"
        onSubmit={() => navigate('/lifecycle-states')}
        createHook={useCreateLifecycleStateMutation}
      />
    </section>
  );
};

export default LifecycleStatesCreate;
