import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { Layout } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { useUsersQuery, SortOrder, QueryMode } from 'GraphQL/queries/users.graphql';
import { ResourceIndex } from 'Components';

const UsersIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useUsersQuery}
        rootKey="users"
        defaultScope={{
          orderBy: { email: SortOrder.Asc },
        }}
        search={{
          toWhere: (search) => {
            if (!search) return {};
            return { email: { contains: search, mode: QueryMode.Insensitive } };
          },
        }}
        createHref="/users/new"
        title="Gebruikers"
        icon={<TeamOutlined />}
      >
        {(user) => (
          <tr key={user.id}>
            <td>
              <NavLink to={`/users/${user.id}/edit`}>{user.email}</NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default UsersIndex;
