import React from 'react';
import { Link } from 'react-router-dom';
import {
  Layout,
  List,
  Typography,
} from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Loading } from 'Components';
import { useCommentsQuery } from 'GraphQL/queries/comments.graphql';
import useAuth from 'Hooks/useAuth';

const DocumentsIndex = () => {
  const { currentUser } = useAuth();
  const { data, loading } = useCommentsQuery({
    variables: {
      userId: currentUser?.id || 0,
    },
  });

  return (
    <Layout>
      <Layout.Content className="resource-content" style={{ marginLeft: 35 }}>
        <section>
          <header style={{
            alignItems: 'center', display: 'flex', padding: '0 6px', marginTop: -2,
          }}
          >
            <CommentOutlined style={{ marginRight: 8 }} />
            <h3 style={{ lineHeight: '32px', margin: 0 }}>
              Reacties
            </h3>
          </header>
          <main>
            {loading
              ? <Loading center pad />
              : (
                <List
                  dataSource={data?.comments}
                  renderItem={(comment) => (
                    <List.Item>
                      <List.Item.Meta
                        title={(
                          <Link to={`/documents/${comment.documentText.document.id}/document-texts/${comment.documentText.id}/edit`}>
                            {`${comment.documentText.document.shortName} - ${comment.documentText.code} ${comment.documentText.name}`}
                          </Link>
                        )}
                        description={(
                          <Typography.Text
                            ellipsis
                            style={{ maxWidth: '100%', opacity: 0.6 }}
                          >
                            {`${comment.user.firstName} ${comment.user.lastName}: ${comment.text}`}
                          </Typography.Text>
                        )}
                      />
                      <span
                        style={{ opacity: 0.6 }}
                        title={comment.createdAt}
                      >
                        {dayjs(comment.createdAt).format('HH:mm - D MMM')}
                      </span>
                    </List.Item>
                  )}
                />
              )}
          </main>
        </section>
      </Layout.Content>
    </Layout>
  );
};

export default DocumentsIndex;
