import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EmployeesForm } from 'Components';

const EmployeesCreate = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  return (
    <section>
      <EmployeesForm
        companyId={Number.parseInt(companyId, 10)}
        onSubmit={() => navigate(`/companies/${companyId}/employees`)}
      />
    </section>
  );
};

export default EmployeesCreate;
