import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import {
  Col,
  Layout,
  Row,
} from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import { useDocumentsQuery, SortOrder, QueryMode } from 'GraphQL/queries/documentsWithState.graphql';
import { ResourceIndex } from 'Components';
import Dot from 'Components/ResourceIndex/Dot';

const DocumentsIndex = () => (
  <Layout>
    <Layout.Sider className="resource-index" width={390}>
      <ResourceIndex
        query={useDocumentsQuery}
        defaultScope={{
          orderBy: { shortName: SortOrder.Asc },
        }}
        search={{
          toWhere: (search) => {
            if (!search) return {};

            return {
              OR: [
                {
                  shortName: { contains: search, mode: QueryMode.Insensitive },
                },
                { name: { contains: search, mode: QueryMode.Insensitive } },
              ],
            };
          },
        }}
        rootKey="documents"
        createHref="/documents/new"
        title="Overzicht wet- en regelgeving"
        icon={<ReadOutlined />}
      >
        {(document) => (
          <tr key={document.id}>
            <td>
              <NavLink to={`/documents/${document.id}/edit`}>
                <Row>
                  <Col flex={1}>
                    {document.shortName}
                  </Col>
                  {document.eunomeaState?.name && (
                    <Col>
                      <Dot title={document.eunomeaState?.name} />
                    </Col>
                  )}
                </Row>
              </NavLink>
            </td>
          </tr>
        )}
      </ResourceIndex>
    </Layout.Sider>
    <Layout.Content className="resource-content">
      <Outlet />
    </Layout.Content>
  </Layout>
);

export default DocumentsIndex;
