import React, { FC, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  TreeSelect,
} from 'antd';
import { CheckOutlined, DeleteOutlined, GlobalOutlined } from '@ant-design/icons';
import { ButtonsBar, QuerySelect } from 'Components';
import { useCreateNewsMessageMutation } from 'GraphQL/mutations/createNewsMessage.graphql';
import { useUpdateNewsMessageMutation } from 'GraphQL/mutations/updateNewsMessage.graphql';
import { useDeleteNewsMessageMutation } from 'GraphQL/mutations/deleteNewsMessage.graphql';
import { DATEPICKER_LOCALE, VALIDATE_MESSAGES } from 'Common/translations';
import { invalidateCache } from 'Common/invalidateCache';
import useAuth from 'Hooks/useAuth';
import { fromGraphql, toGraphql } from 'Common/graphqlTransform';
import {
  useDocumentsQuery,
  SortOrder,
} from 'GraphQL/queries/documents.graphql';
import { useNewsCategoriesQuery } from 'GraphQL/queries/settings/newsCategories.graphql';
import { useCompaniesQuery, CompanyTypeNames } from 'GraphQL/queries/companies.graphql';
import { usePoliciesQuery } from 'GraphQL/queries/policies.graphql';
import { TreeNode } from 'antd/lib/tree-select';

interface NewsMessage {
  id: number,
  description: string,
  documents?: {
    id: number,
  }[] | null,
  link?: string | null,
  newsCategory?: {
    id: number,
  } | null,
  newsType: string,
  policies: {
    id: number
  }[],
  published: string,
  source: {
    id: number,
  },
  subject: string,
}

interface NewsMessagesFormProps {
  newsMessage?: NewsMessage | null,
  onSubmit?: (user: any) => void,
  onDelete?: (user: any) => void,
}

const transforms = {
  belongsTo: ['newsCategory', 'source'],
  hasMany: ['documents', 'policies'],
  date: ['published'],
  attachment: [],
};

const renderPolicies = (policiesTree: any) => policiesTree.map((p: any) => (
  <TreeNode disabled={p.code.length < 4} key={p.key} value={p.key} title={`${p.code} ${p.name}`} name={p.name}>
    {p.children && renderPolicies(p.children)}
  </TreeNode>
));

const NewsMessagesForm: FC<NewsMessagesFormProps> = ({ newsMessage, onSubmit, onDelete }: NewsMessagesFormProps) => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [link, setLink] = useState(newsMessage?.link || '');
  const [form] = Form.useForm();
  const { hasRole } = useAuth();

  const [createNewsMessageMutation, { loading: loadingCreate }] = useCreateNewsMessageMutation({ ...invalidateCache('newsMessages') });
  const [updateNewsMessageMutation, { loading: loadingUpdate }] = useUpdateNewsMessageMutation();
  const [deleteNewsMessageMutation] = useDeleteNewsMessageMutation({ ...invalidateCache('newsMessages') });

  const { data: policiesData } = usePoliciesQuery({ fetchPolicy: 'network-only' });

  const handleCreate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await createNewsMessageMutation({
        variables: {
          data: toGraphql(formData, transforms, 'CREATE'),
        },
      });
      notification.success({ message: 'Nieuwsbericht is toegevoegd' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Nieuwsbericht toevoegen mislukt' });
    }
  };

  const handleUpdate = async (formData: FormData) => {
    try {
      setIsBlocking(false);
      const { data } = await updateNewsMessageMutation({
        variables: {
          data: toGraphql(formData, transforms, 'UPDATE'),
          id: newsMessage!.id,
        },
      });
      notification.success({ message: 'Nieuwsbericht is bewerkt' });
      if (onSubmit) onSubmit(data);
    } catch (e) {
      notification.error({ message: 'Nieuwsbericht opslaan mislukt' });
    }
  };

  const handleDelete = async () => {
    try {
      setIsBlocking(false);
      setDeleteModal(false);
      const { data } = await deleteNewsMessageMutation({
        variables: { id: newsMessage!.id },
      });
      notification.success({ message: 'Nieuwsbericht is verwijderd' });
      if (onDelete) onDelete(data);
    } catch (e) {
      notification.error({ message: 'Nieuwsbericht verwijderen mislukt' });
    }
  };

  useEffect(() => form.resetFields(), [newsMessage]);

  const value = newsMessage ? fromGraphql(newsMessage, transforms) : undefined;

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={value}
      onFinish={newsMessage ? handleUpdate : handleCreate}
      validateMessages={VALIDATE_MESSAGES}
      onFieldsChange={() => setIsBlocking(true)}
    >
      <Prompt
        when={isBlocking && hasRole('ADMIN', 'NEWS_EDITOR')}
        message="Er zijn niet opgeslagen wijzigingen. Weet u zeker dat u de pagina wil verlaten?"
      />

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Onderwerp"
            name="subject"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Datum"
            name="published"
            rules={[{ required: true }]}
          >
            <DatePicker
              locale={DATEPICKER_LOCALE}
              format="DD-MM-YYYY"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Type bericht"
            name="newsType"
            rules={[{ required: true }]}
          >
            <Select placeholder="Type">
              <Select.Option value="JURISPRUDENCE">Jurisprudentie</Select.Option>
              <Select.Option value="LITERATURE">Vakliteratuur</Select.Option>
              <Select.Option value="NEWSLETTER">Nieuwsbrief</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Gerelateerde Wet &amp; Regelgeving"
            name={['documents']}
          >
            <QuerySelect
              allowClear
              mode="multiple"
              query={useDocumentsQuery}
              rootKey="documents"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
              titleProp="shortName"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Categorie"
            name={['newsCategory', 'id']}
          >
            <QuerySelect
              allowClear
              query={useNewsCategoriesQuery}
              rootKey="newsCategories"
              defaultScope={{
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Bron"
            name={['source', 'id']}
            rules={[{ required: true }]}
          >
            <QuerySelect
              query={useCompaniesQuery}
              rootKey="companies"
              defaultScope={{
                where: { types: { some: { name: { equals: CompanyTypeNames.NewsPublisher } } } },
                orderBy: { name: SortOrder.Asc },
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Omschrijving"
        name="description"
        rules={[{ required: true }]}
      >
        <Input.TextArea autoSize={{ minRows: 2, maxRows: 16 }} />
      </Form.Item>

      <Form.Item
        label="Link"
        name="link"
      >
        <Input
          onChange={(event) => setLink(event.target.value)}
          addonAfter={(
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              aria-label="Open link in nieuw tabblad"
              title="Open link in nieuw tabblad"
            >
              <GlobalOutlined />
            </a>
          )}
        />
      </Form.Item>

      <Form.Item
        label="Gerelateerde beleidsregel"
        name="policies"
      >
        <TreeSelect
          showSearch
          multiple
          style={{ width: '100%' }}
          dropdownStyle={{ height: 'auto', overflow: 'auto' }}
          placeholder="Selecteer beleidsregel"
          allowClear
          filterTreeNode={(searchValue, node) => new RegExp(searchValue, 'gi').test(node?.name)}
        >
          {policiesData?.policies && renderPolicies(policiesData?.policies)}
        </TreeSelect>
      </Form.Item>

      {hasRole('ADMIN', 'NEWS_EDITOR') && (
        <ButtonsBar>
          {newsMessage && (
            <>
              <Button
                danger
                type="primary"
                onClick={() => setDeleteModal(true)}
                icon={<DeleteOutlined />}
              >
                Verwijderen
              </Button>
              <Modal
                onCancel={() => setDeleteModal(false)}
                title="Bevestig uw keuze"
                visible={deleteModal}
                cancelText="Annuleren"
                okText="Ik weet het zeker"
                onOk={handleDelete}
                okButtonProps={{ danger: true }}
              >
                {`Weet u zeker dat u het nieuwsbericht ${newsMessage?.subject} wilt verwijderen?`}
              </Modal>
            </>
          )}
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingCreate || loadingUpdate}
            icon={<CheckOutlined />}
          >
            Opslaan
          </Button>
        </ButtonsBar>
      )}

    </Form>
  );
};

export default NewsMessagesForm;
