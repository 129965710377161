import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SettingsForm } from 'Components';
import { useCreateDocumentTypeMutation } from 'GraphQL/mutations/settings/createDocumentType.graphql';

const DocumentTypesCreate = () => {
  const navigate = useNavigate();

  return (
    <section>
      <SettingsForm
        entity="W&amp;R-type"
        cacheKey="documentTypes"
        onSubmit={() => navigate('/document-types')}
        createHook={useCreateDocumentTypeMutation}
      />
    </section>
  );
};

export default DocumentTypesCreate;
