// https://github.com/react-component/field-form/blob/master/src/utils/messages.ts
import nl_NL from 'antd/lib/date-picker/locale/nl_NL';

/* eslint no-template-curly-in-string: 0 */
export const VALIDATE_MESSAGES = {
  required: 'Dit is een verplicht veld',
  types: {
    email: 'Dit is geen correct emailadres',
  },
  string: {
    min: 'Minimaal ${min} tekens',
  },
};

export const DATEPICKER_LOCALE = nl_NL;

export default {
  VALIDATE_MESSAGES,
};
