import React, { useEffect, useMemo } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
} from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import useAuth from 'Hooks/useAuth';
import { VALIDATE_MESSAGES } from 'Common/translations';
import logo from 'Assets/logo.png';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';

interface FormData {
  email: string,
  password: string,
}

const LoginView = () => {
  const {
    login, loginLoading, loginError, isAuthenticated,
  } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (formData: FormData) => {
    try {
      await login({ variables: formData });
    } catch (error) {
      // error is rendered with loginError
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated]);

  const hasError = useMemo(() => loginError?.graphQLErrors, [loginError]);
  const errorMessage = hasError ? loginError?.graphQLErrors[0].message : '';

  return (
    <div className={styles.login}>
      <Form
        onFinish={handleSubmit}
        validateMessages={VALIDATE_MESSAGES}
        layout="vertical"
      >
        <img
          className={styles.logo}
          src={logo}
          alt="Eunomea"
        />

        {hasError && (
        <Alert
          type="error"
          message={errorMessage === 'User account is locked' ? 'Account tijdelijk geblokkeerd' : 'Gebruikersnaam en/of wachtwoord onjuist'}
          style={{ marginBottom: 18, fontSize: 13 }}
        />
        )}

        <Form.Item
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input
            type="email"
            placeholder="E-mailadres"
            autoComplete="username"
            autoFocus
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Vul een wachtwoord in' },
          ]}
        >
          <Input.Password
            placeholder="Wachtwoord"
            autoComplete="current-password"
          />
        </Form.Item>

        <Form.Item>
          <Button
            block
            htmlType="submit"
            icon={<LoginOutlined />}
            loading={loginLoading}
            type="primary"
          >
            Inloggen
          </Button>
        </Form.Item>
        <p>Ongeauthoriseerde toegang is verboden</p>
      </Form>
    </div>
  );
};

export default LoginView;
