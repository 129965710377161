import { ApolloCache } from '@apollo/client';

export const invalidateCache = (key: string) => ({
  update: (cache: ApolloCache<unknown>) => {
    cache.modify({
      fields: {
        [key](_, { DELETE }) {
          return DELETE;
        },
      },
    });
  },
});
