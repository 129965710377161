import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEunomeaStateQuery } from 'GraphQL/queries/settings/eunomeaState.graphql';
import { useUpdateEunomeaStateMutation } from 'GraphQL/mutations/settings/updateEunomeaState.graphql';
import { useDeleteEunomeaStateMutation } from 'GraphQL/mutations/settings/deleteEunomeaState.graphql';
import { Loading, SettingsForm } from 'Components';

const EunomeaStatesEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, loading } = useEunomeaStateQuery({
    variables: {
      id: Number.parseInt(id, 10),
    },
    fetchPolicy: 'network-only',
  });

  return (
    <section>
      {loading
        ? <Loading center pad />
        : (
          <SettingsForm
            entity="W&amp;R-Eunomea status"
            cacheKey="eunomeaStates"
            settingsState={data?.eunomeaState}
            onDelete={() => navigate('/eunomea-states')}
            updateHook={useUpdateEunomeaStateMutation}
            deleteHook={useDeleteEunomeaStateMutation}
          />
        )}
    </section>
  );
};

export default EunomeaStatesEdit;
